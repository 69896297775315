import { CHARTS_IDS, DEFAULT_VISIBILITY_ELEMENTS, SIMPLE_MODE_STEPS, SpvActions, SpvLoadings } from 'constants/products/solarpv';
import {
    CHART_SENSITIBITY_ANALYSIS_NAMES,
    LAST_ADDED_AREA_INDEX,
    MESH_TYPES,
    PROJECT_SUMMARY_CHART,
    SPV_ERROR_TAGS,
    SPV_LOADINGS,
} from 'constants/products/spvPro';
import { isDefined, isFieldDefined, getBoolean, intlMessages } from 'services/util/auxiliaryUtils';
import clone from 'fast-copy';
import { getKPIsOnDemand, usefulAreaHandler } from 'services/products/solarpv/solarpvV3';
import {
    onDiscardExclusionsChanges,
    selectPolygon,
    buildDefaultAreaMeshFromPolygon,
    buildDefaultExclusionPolygon,
    buildDefaultPolygon,
    setElementsVisibility,
} from 'services/products/solarpv/map';
import { companyProflesWithInjectionTariffBySims, onDiscardExclusionsChangesHandler } from 'services/products/solarpv';
import { getCompanyProfileIds } from 'services/user';
import { LatLng } from 'components/Products/solarpv/v3/map/Polygon/Coordinates';
import { SPV_OVERLAY_MODES } from 'constants/products/solarpv';
import { ISimpleMode, ISpvAction, TSpvState } from 'interfaces/products/solarpv/index';
import { SYSTEM_SETTINGS_GROUPS, SYSTEM_SETTINGS_TABS } from 'constants/products/solarpv';
import { ISpvInputs } from 'interfaces/products/solarpv/state/sims';
import { Polygon } from 'components/Products/solarpv/v3/map/Polygon';
import { ISpvCharts } from 'interfaces/products/solarpv/state/charts';
import { useProposalStore } from 'store/proposal';
import { notify } from 'services/@efz/notify';
/* import { calcTotalPanelsB2B } from 'services/products/solarpvSimple'; */

const initialSPVLoadings = {
    simulation: false,
    usefulArea: false,
    minRowDistance: false,
    estimatedStructures: null,
    autoExclusions: false,
    autoBuildings: false,
    buildings: false,
    autoSplit: false,
    productions: false,
    consumptions: true,
    spvData: false,
    sensibilityAnalysis: false,
    sensibilityAnalysisBattery: false,
    inverters: false,
    spvSimpleKpisRequests: true,
    spvSimpleRoofIdentification: false,
    loadProject: false,
    changeAddress: false,
    loadLayers: false,
};

const initialTags = {
    info: [],
    error: [],
};

const initialSpvOptions = {
    batteryPowers: [],
    completeBatteryPowers: [],
};

const initialOpenDialogs = {
    deleteAllPanels: false,
    deletePanels: null,
    editStructure: null,
    editSlope: null,
    editPanelRepresentation: false,
    editOrientation: null,
    inverters: false,
    detailedCosts: false,
    productionLosses: false,
    optimization: false,
    graph: false,
    autoExclusions: false,
    autoBuildings: false,
    autoSplit: false,
    exclusionsConfirmation: false,
    remotesConfimation: false,
    warnDataBatteryDelete: false,
    imgContract: false,
    switchToSimpleMode: false,
    deleteExclusions: false,
    deleteAllExclusions: false,
    editBuilding: false,
    googleSolarLayers: false,
    deleteBuilding: false,
    deleteAllBuildings: false,
};

export const initialOverlayModes = {
    [SPV_OVERLAY_MODES.MOVE]: false,
    [SPV_OVERLAY_MODES.ORIENTATION]: false,
    [SPV_OVERLAY_MODES.PANEL_EDITOR]: false,
    [SPV_OVERLAY_MODES.STAMP]: false,
    [SPV_OVERLAY_MODES.ALIGNMENT]: false,
    [SPV_OVERLAY_MODES.ADDRESS]: false,
    [SPV_OVERLAY_MODES.MAP_OPTIONS]: false,
    // TODO: add more here later...
};

const DEFAULT_INPUTS: ISpvInputs = {
    remote: null,
    buildings: [],
    remuneration_type_id: null,
    areas: [], //passar coords do roof no simples
    coordinates_avg: { lat: null, long: null },
    coordinates_conversion_factor: null,
    exclusions: [],
    total_panels: 0,
    total_areas: 0,
    min_tec_panels: 0,
    max_tec_panels: 0,
    panel_id: null,
    range_id: null,
    injection_tariff: null,
    injection_tariff_distributor: null,
    limit_network_sale: null,
    network_sale: null,
};

const rangeSelectedDefault = clone({
    area_util_coplanar: null,
    area_util_triangular: null,
    area_util_triangular_apos_15_graus: null,
    descricao: null,
    id: null,
    is_default: null,
    kits: null,
    max_kwp: null,
    min_kwp: null,
    modo_calculo_estrutura_id: null,
    modo_dimensionamento_inversores_id: null,
    paineis: null,
    pv_modo_kits_id: null,
    pv_modo_limite_potencia_id: null,
});

export const SIMPLE_MODE_DEFAULT: ISimpleMode = {
    step: SIMPLE_MODE_STEPS.ADDRESS,
    simulationKpis: null,
    inputs: {
        ...DEFAULT_INPUTS,
    },
    facilityCoordinates: {
        lat: 0,
        lng: 0,
    },
    isManualDrawing: false,
    showAlertManualDrawing: true,
    roof: null,
    roofType: null,
    roofTypeStructures: null,
    roofPolygon: null,
    polygonGroups: [],
    exclusionGroups: [],
    buildingGroups: [],
    tags: initialTags,
    isRequestRoofFail: false,
};

export const initialSPVProReducer: TSpvState = {
    setSimpleModeStepOnHandler: () => {},
    cleanupSPVSimple: () => {},
    resetCacheSPVSimple: () => {},
    simpleMode: {
        ...SIMPLE_MODE_DEFAULT,
    },
    isSimpleMode: false,
    isMounting: true,
    doInitFetch: 0,
    isSPVv3: false,
    isB2C: false,
    pvKitsB2C: [],
    optimizationsData: [],
    hasClientOpenAreaImage: false,
    hasAutoUpdateKPIs: false,
    hasUpdatedKPIS: false,
    isComputeKPIs: false,
    simulations: null,
    standaloneSims: null,
    bundleSims: null,
    isIncreasePower: false,
    inputs: {
        ...DEFAULT_INPUTS,
    },
    usefulAreaData: [],
    productions: null,
    hasProduction: null,
    consumptions: null,
    loadings: initialSPVLoadings,
    tags: initialTags,
    modes: initialOverlayModes,
    pvOptions: null,
    ranges: [],
    defaultInputs: clone({
        ...DEFAULT_INPUTS,
    }),
    rangeSelected: rangeSelectedDefault,
    structures: [],
    solarPanelsData: [],
    options: initialSpvOptions,
    groupTab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
    productSelection: SYSTEM_SETTINGS_GROUPS.PANELS,
    regionsGD: [],
    info: {
        userTypeID: null,
        productID: null,
        facilityID: null,
    },
    inverters: {
        list: [],
        listWithCosts: [],
        combination: [],
        recomendedCombination: [],
        hasInverters: true,
    },
    dialogs: initialOpenDialogs,
    panels: {
        total: 0,
        totalAreas: 0,
        maxTecPanels: null,
        minTecPanels: null,
        drawn: [],
    },
    polygonGroups: [],
    selectedArea: null,
    exclusionGroups: [],
    selectedExclusion: null,
    buildingGroups: [],
    selectedBuilding: null,
    graphs: {
        data: null,
        tags: {
            consumptionProduction: [],
            sensibilityAnalysis: [],
        },
        visibleChart: null,
        visibleDialogChart: null,
        sensibilityAnalysis: {
            data: null,
            batteryData: null,
            sensibilityAnalysisPointsType: null,
            sensibilityAnalysisDialogPointsType: null,
        },
        showSensibilityAnalysis: false,
        showSensibilityAnalysisBattery: false,
    },
    injectionConfigs: {
        default_network_sale: false,
        editable_network_sale: false,
        show_limit_injection: false,
        default_limit_injection: false,
        editable_limit_injection: false,
        default_limit_value: '',
        editable_limit_value: false,
        editable_injection_tariff: false,
        injection_tariff_default: '',
    },
    companyProfileId: null,
    mapPrintState: 'idle',
    showBatteryVisibility: false,
    initialSim: true,
    project: null,
    visibility: DEFAULT_VISIBILITY_ELEMENTS.panels,
    hasOrientationChanged: false,
    mapOptions: {
        labels: false,
        selectedLayerName: null,
        areLayersDownloaded: false,
    },
};

export const spvProReducer = (state = initialSPVProReducer, action: ISpvAction) => {
    // console.log('efz-> spvProReducer', action);
    switch (action.type) {
        case SpvActions.SET_INIT: {
            if (isFieldDefined(useProposalStore.getState().img_contract_filename)) useProposalStore.getState().actions.resetAll();
            const { payload } = action;
            const {
                isB2C,
                pvKitsB2C,
                optimizationsData,
                hasClientOpenAreaImage,
                simulations,
                standaloneSims,
                bundleSims,
                pvOptions,
                inputs,
                ranges,
                solarPanelsData,
                structures,
                regionsGD,
                facilityID,
                userTypeID,
                productID,
                cfgCharts,
                cfgSensibilityAnalysisChart,
                coordinates_avg_default,
                companyProfileId,
                isIncreasePower,
                showSensibilityAnalysis,
                showBatteryVisibility,
                showSensibilityAnalysisBattery,
                project,
            } = payload;

            const { range_id, coordinates_avg, areas } = inputs;

            // parse values
            const _ranges = ranges?.map((el) => {
                return {
                    ...el,
                    id: el.id,
                    pv_modo_kits_id: parseInt(el.pv_modo_kits_id),
                    pv_modo_limite_potencia_id: parseInt(el.pv_modo_limite_potencia_id),
                };
            });

            const rangeSelected = _ranges?.find((el) => parseInt(el.id) === parseInt(range_id)) ?? rangeSelectedDefault;
            if (!rangeSelected?.paineis?.find((p) => p.id === inputs.panel_id)) {
                inputs.panel_id = rangeSelected?.paineis?.find((p) => p.is_default)?.id;
                notify(
                    intlMessages('server.error.spv.PANEL_NONEXISTENT_USING_DEFAULT.message'),
                    'info',
                    intlMessages('server.error.spv.PANEL_NONEXISTENT_USING_DEFAULT.title')
                );
            }

            const isTabletOrMobile = getBoolean(localStorage.getItem('isTabletOrMobile'));
            const onSimpleMode =
                inputs?.isSimpleMode ??
                (pvOptions?.configs_modules?.show_simple && (!pvOptions?.configs_modules?.show_advanced || isTabletOrMobile));

            // Add previous areas to polygonGroups
            const newPolygonGroups = clone(state.polygonGroups);
            const newExclusionGroups = clone(state.exclusionGroups);
            const newBuildingGroups = clone(state.buildingGroups);

            if (!onSimpleMode) {
                if (areas?.length > 0) {
                    areas.forEach((area, areaIndex) => {
                        const isPolygonSelected = areaIndex === LAST_ADDED_AREA_INDEX;
                        const polygon = buildDefaultPolygon(area.coordinates, isPolygonSelected, newPolygonGroups, {
                            id: area.id,
                            draggable: true,
                            editable: true,
                            center: area?.centroid,
                        });
                        newPolygonGroups.push(polygon);
                        area.selected = isPolygonSelected;
                    });
                    // polygonGroups will never have items b4 this SET_INIT
                    // so we can say that the selectedAreaId will be the same as the polygonGroups[LAS_ADDED_AREA_INDEX].id
                }

                // Add exclusions
                if (inputs?.exclusions?.length > 0) {
                    inputs.exclusions.forEach((exclusion) => {
                        //const isExclusionSelected = exclusionIndex === LAST_ADDED_AREA_INDEX
                        const exclusionArea = buildDefaultExclusionPolygon(
                            exclusion.coordinates.map((c) => new LatLng(c[0], c[1])),
                            false,
                            newExclusionGroups,
                            {
                                id: exclusion.id,
                                draggable: false,
                                editable: true,
                                center: exclusion?.centroid,
                                zIndex: 20,
                                height: exclusion?.height ?? 0,
                            }
                        );
                        newExclusionGroups.push(exclusionArea);
                        exclusion.selected = false;
                    });
                    // polygonGroups will never have items b4 this SET_INIT
                    // so we can say that the selectedAreaId will be the same as the polygonGroups[LAS_ADDED_AREA_INDEX].id
                }

                // Add buildings
                const newBuildingGroups = clone(state.buildingGroups);
                if (inputs?.buildings?.length > 0) {
                    inputs.buildings.forEach((building) => {
                        //const isBuildingSelected = buildingIndex === LAST_ADDED_AREA_INDEX
                        const buildingArea = buildDefaultExclusionPolygon(building.coordinates, false, newBuildingGroups, {
                            id: building.id,
                            draggable: false,
                            editable: true,
                            center: building?.centroid,
                            zIndex: 20,
                        });
                        newBuildingGroups.push(buildingArea);
                        building.selected = false;
                    });
                    // polygonGroups will never have items b4 this SET_INIT
                    // so we can say that the selectedAreaId will be the same as the polygonGroups[LAS_ADDED_AREA_INDEX].id
                }
            }

            const injectionConfigs = {
                default_network_sale: pvOptions?.default_network_sale,
                editable_network_sale: pvOptions?.editable_network_sale,
                show_limit_injection: pvOptions?.show_limit_injection,
                default_limit_injection: pvOptions?.default_limit_injection,
                editable_limit_injection: pvOptions?.editable_limit_injection,
                default_limit_value: pvOptions?.default_limit_value,
                editable_limit_value: pvOptions?.editable_limit_value,
                editable_injection_tariff: pvOptions?.editable_injection_tariff,
                injection_tariff_default: pvOptions?.injection_tariff_default ?? '',
            };

            const defaultInputs = clone({
                ...DEFAULT_INPUTS,
                injection_tariff:
                    ![getCompanyProfileIds().ROMANDE].includes(companyProfileId) ? injectionConfigs?.injection_tariff_default : null,
                network_sale: injectionConfigs?.default_network_sale,
                limit_network_sale: injectionConfigs?.default_limit_injection ? injectionConfigs.default_limit_value : false,
                coordinates_avg: coordinates_avg_default,
                range_id: _ranges?.find((el) => el.is_default)?.id ?? null,
                panel_id: _ranges?.find((el) => el.is_default)?.paineis?.find((el) => el.is_default)?.id ?? null,
                remuneration_type_id: inputs?.remuneration_type_id ?? null,
                max_tec_panels: inputs?.max_tec_panels ?? 0,
                min_tec_panels: inputs?.min_tec_panels ?? 0,
            });

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    inputs:
                        onSimpleMode ?
                            {
                                ...state.simpleMode.inputs,
                                ...defaultInputs,
                            }
                        :   {
                                ...state.simpleMode.inputs,
                                ...inputs,
                            },
                    facilityCoordinates: {
                        ...state.simpleMode.facilityCoordinates,
                        lat: coordinates_avg_default?.lat,
                        lng: coordinates_avg_default?.long,
                    },
                    step: inputs?.step ?? SIMPLE_MODE_STEPS.ADDRESS,
                },
                isSimpleMode: onSimpleMode,
                isB2C,
                pvKitsB2C,
                optimizationsData,
                hasClientOpenAreaImage,
                simulations,
                standaloneSims,
                bundleSims,
                pvOptions,
                structures,
                defaultInputs,
                isIncreasePower,
                inputs: onSimpleMode ? defaultInputs : { ...inputs, coordinates_avg },
                ranges: _ranges,
                rangeSelected,
                solarPanelsData,
                hasAutoUpdateKPIs: pvOptions?.default_update_kpis_automatic ?? true,
                regionsGD,
                info: {
                    facilityID,
                    userTypeID,
                    productID,
                },
                polygonGroups: newPolygonGroups,
                exclusionGroups: newExclusionGroups,
                buildingGroups: newBuildingGroups,
                selectedArea: areas[LAST_ADDED_AREA_INDEX]?.id ?? null,
                selectedExclusion: /* newExclusionGroups[LAST_ADDED_AREA_INDEX]?.id ??  */ null,
                selectedBuilding: /* newBuildingGroups[LAST_ADDED_AREA_INDEX]?.id ??  */ null,
                graphs: {
                    ...state.graphs,
                    visibleChart: cfgCharts?.[PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]?.value,
                    visibleDialogChart: cfgCharts?.[PROJECT_SUMMARY_CHART.CONSUMPTION_PRODUCTION]?.value,
                    sensibilityAnalysis: {
                        ...state.graphs.sensibilityAnalysis,
                        sensibilityAnalysisPointsType: cfgSensibilityAnalysisChart?.[CHART_SENSITIBITY_ANALYSIS_NAMES.TRANSATIONAL]?.value,
                        sensibilityAnalysisDialogPointsType:
                            cfgSensibilityAnalysisChart?.[CHART_SENSITIBITY_ANALYSIS_NAMES.TRANSATIONAL]?.value,
                    },
                    showSensibilityAnalysis,
                    showSensibilityAnalysisBattery,
                },
                injectionConfigs,
                companyProfileId,
                isMounting: false,
                doInitFetch: inputs?.areas?.length > 0 ? state.doInitFetch + 1 : state.doInitFetch,
                panels: {
                    maxTecPanels: inputs?.max_tec_panels ?? null,
                    minTecPanels: inputs?.min_tec_panels ?? null,
                    totalAreas: inputs?.areas?.reduce((accumulator, currentValue) => accumulator + currentValue.area, 0) ?? 0,
                    total: inputs?.areas?.reduce((accumulator, currentValue) => accumulator + currentValue.panels_number, 0) ?? 0,
                },
                loadings: {
                    ...state.loadings,
                    [SPV_LOADINGS.PRODUCTIONS]: inputs?.areas?.length > 0,
                    [SPV_LOADINGS.SENSIBILITY_ANALYSIS]: showSensibilityAnalysis,
                    [SPV_LOADINGS.SENSIBILITY_ANALYSIS_BATTERY]: isFieldDefined(inputs?.battery) && showSensibilityAnalysisBattery,
                },
                showBatteryVisibility,
                project,
                initialSim: inputs.areas.length === 0 ? false : state.initialSim,
                visibility: onSimpleMode ? DEFAULT_VISIBILITY_ELEMENTS.buildings : state.visibility,
            };
        }
        case SpvActions.SET_SIMULATIONS: {
            const { simulations, standaloneSims, bundleSims, inputs, newProject } = action.payload;
            const injection_tariff =
                companyProflesWithInjectionTariffBySims(Number(state.companyProfileId)) ?
                    bundleSims?.injection_tariff ?? standaloneSims?.injection_tariff
                :   inputs?.injection_tariff;

            return {
                ...state,
                inputs: {
                    ...inputs,
                    injection_tariff,
                },
                simulations,
                standaloneSims,
                bundleSims,
                loadings: {
                    ...state.loadings,
                    [SPV_LOADINGS.SIMULATIONS]: false,
                    loadProject: false,
                },
                tags: {
                    ...state.tags,
                    info: !isDefined(bundleSims) ? state.tags.info.filter((tag) => !tag?.battery) : state.tags.info,
                },
                hasUpdatedKPIS: true,
                isComputeKPIs: false,
                initialSim: false,
                shortcut: null,
                ...newProject,
            };
        }
        case SpvActions.RESET_BUNDLE_SIMS: {
            return {
                ...state,
                bundleSims: null,
                tags: {
                    ...state.tags,
                    info: state.tags.info.filter((tag) => !tag?.battery),
                },
            };
        }
        case SpvActions.SET_HAS_UPDATED_KPIS: {
            return {
                ...state,
                hasUpdatedKPIS: action.payload.hasUpdatedKpis,
                simulations: null,
                standaloneSims: null,
                bundleSims: null,
                productions: null,
                hasProduction: false,
            };
        }
        case SpvActions.HIDE_PROJECT_SUMMARY: {
            return {
                ...state,
                isComputeKPIs: false,
                hasAutoUpdateKPIs: false,
            };
        }
        case SpvActions.SET_AUTO_UPDATE_KPIS: {
            return {
                ...state,
                hasAutoUpdateKPIs: action.payload?.hasAutoUpdateKPIs,
                isComputeKPIs: false,
                hasOrientationChanged: false,
            };
        }
        case SpvActions.SET_IS_COMPUTE_KPIS: {
            return {
                ...state,
                isComputeKPIs: action.payload.computeKpis,
                hasUpdatedKPIS: action.payload.computeKpis,
                simulations: action.payload.computeKpis ? state.simulations : null,
                standaloneSims: action.payload.computeKpis ? state.standaloneSims : null,
                bundleSims: action.payload.computeKpis ? state.bundleSims : null,
                productions: action.payload.computeKpis ? state.productions : null,
            };
        }
        case SpvActions.SET_CHART_PRODUCTIONS: {
            const { isRequesting, productions } = action.payload;
            return {
                ...state,
                productions,
                loadings: {
                    ...state.loadings,
                    [SPV_LOADINGS.PRODUCTIONS]: isRequesting,
                },
                hasProduction: isFieldDefined(productions),
            };
        }
        case SpvActions.SET_CHART_CONSUMPTIONS: {
            const { isRequesting, consumptions } = action.payload;
            return {
                ...state,
                consumptions,
                loadings: {
                    ...state.loadings,
                    [SPV_LOADINGS.CONSUMPTIONS]: isRequesting,
                },
            };
        }
        case SpvActions.SET_LOADINGS: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    ...action.payload.loadings.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
                },
            };
        }
        case SpvActions.RESET_LOADINGS: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    ...initialSPVLoadings,
                },
            };
        }
        case SpvActions.SET_MODES: {
            let newVisibility = clone(state.visibility);
            if (
                [
                    SPV_OVERLAY_MODES.MOVE,
                    SPV_OVERLAY_MODES.ORIENTATION,
                    SPV_OVERLAY_MODES.PANEL_EDITOR,
                    SPV_OVERLAY_MODES.STAMP,
                    SPV_OVERLAY_MODES.ALIGNMENT,
                ].includes(action?.payload?.modes?.[0]?.name)
            ) {
                newVisibility = {
                    areas: state?.productSelection === SYSTEM_SETTINGS_GROUPS.PANELS ? true : state?.visibility?.areas,
                    panels: state?.productSelection === SYSTEM_SETTINGS_GROUPS.PANELS ? true : state?.visibility?.panels,
                    exclusions: state?.productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS ? true : state?.visibility?.exclusions,
                    buildings: state?.productSelection === SYSTEM_SETTINGS_GROUPS.BUILDINGS ? true : state?.visibility?.buildings,
                };
                setElementsVisibility(newVisibility, state?.productSelection);
            }

            const allModesDisabled = Object.fromEntries(Object.entries(state.modes).map((entry) => [entry?.at(0), false]));

            return {
                ...state,
                modes: {
                    ...allModesDisabled,
                    ...action.payload.modes.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
                },
                hasUpdatedKPIS: false,
                visibility: newVisibility,
            };
        }
        case SpvActions.SET_TAGS: {
            const { tags, type } = action.payload;
            let newState = state;

            if (state.isSimpleMode) {
                newState = {
                    ...newState,
                    simpleMode: {
                        ...newState.simpleMode,
                        tags: {
                            ...newState.simpleMode.tags,
                            [type]: tags,
                        },
                    },
                };
            } else {
                newState = {
                    ...newState,
                    tags: {
                        ...newState.tags,
                        [type]: tags,
                    },
                };
            }

            if (
                type === 'error' &&
                tags.length > 0 &&
                !tags.find((x) =>
                    [SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_CONTRACTED_POWER, SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_REQUIRED_POWER].some(
                        (y) => y === x.tag
                    )
                )
            )
                newState = {
                    ...newState,
                    loadings: {
                        ...newState.loadings,
                        simulation: false,
                    },
                };

            return newState;
        }
        case SpvActions.SET_DELETE_ALL_GROUPS: {
            return {
                ...state,
                inputs: action.payload.newInputs,
                loadings: {
                    ...state.loadings,
                    simulation: false,
                },
                dialogs: {
                    ...state.dialogs,
                    deleteAllPanels: false,
                },
                simulations: initialSPVProReducer.simulations,
                standaloneSims: initialSPVProReducer.standaloneSims,
                bundleSims: initialSPVProReducer.bundleSims,
                tags: initialTags,
            };
        }
        case SpvActions.SET_INPUTS: {
            if (state.isSimpleMode) {
                return {
                    ...state,
                    simpleMode: {
                        ...state.simpleMode,
                        inputs: {
                            ...state.simpleMode.inputs,
                            ...action.payload.inputs,
                        },
                        //PANELS ON SIMPLE MODE ??
                    },
                };
            } else {
                return {
                    ...state,
                    inputs: action.payload.inputs,
                    panels: {
                        ...state.panels,
                        maxTecPanels: action.payload?.inputs?.max_tec_panels ?? null,
                        minTecPanels: action.payload?.inputs?.min_tec_panels ?? null,
                        totalAreas:
                            action.payload?.inputs?.areas?.reduce((accumulator, currentValue) => accumulator + currentValue.area, 0) ?? 0,
                        total:
                            action.payload?.inputs?.areas?.reduce(
                                (accumulator, currentValue) => accumulator + currentValue.panels_number,
                                0
                            ) ?? 0,
                    },
                    ...getKPIsOnDemand(state),
                };
            }
        }
        case SpvActions.RESET_INPUTS: {
            return {
                ...state,
                simulations: initialSPVProReducer.simulations,
                standaloneSims: initialSPVProReducer.standaloneSims,
                bundleSims: initialSPVProReducer.bundleSims,
                tags: initialTags,
            };
        }
        case SpvActions.SET_RANGE_ID: {
            const { range_id, newInputs, options = {} } = action.payload;
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                rangeSelected: {
                    ...(state?.ranges?.find((el) => Number(el.id) === parseInt(range_id)) ?? {}),
                },
                productSelection: options?.deleteBattery ? SYSTEM_SETTINGS_GROUPS.PANELS : state.productSelection,
                bundleSims: options?.deleteBattery ? null : state.bundleSims,
                tags: {
                    ...state.tags,
                    info: options?.deleteBattery ? state.tags.info.filter((tag) => !tag?.battery) : state.tags.info,
                },
            };
        }
        case SpvActions.SET_RANGE_PANEL_ID:
        case SpvActions.SET_MAX_SIZE:
        case SpvActions.SET_ROW_SPACE:
        case SpvActions.SET_PANEL_REPRESENTATION:
        case SpvActions.SET_ALIGNMENT: {
            return {
                ...state,
                inputs: action.payload.newInputs,
                ...getKPIsOnDemand(state),
            };
        }
        case SpvActions.RESET_TAGS: {
            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    tags: initialTags,
                },
                tags: initialTags,
            };
        }
        case SpvActions.SET_INJECTION_LIMITATION: {
            const { limit_network_sale } = action.payload;
            const { inputs, hasAutoUpdateKPIs, hasUpdatedKPIS } = state;

            let stateKpis = {};
            if (!hasAutoUpdateKPIs || hasUpdatedKPIS) {
                stateKpis = {
                    bundleSims: null,
                    simulations: null,
                    standaloneSims: null,
                    productions: null,
                    hasProduction: false,
                    hasUpdatedKPIS: false,
                    isComputeKPIs: false,
                };
            }

            return {
                ...state,
                inputs: {
                    ...inputs,
                    limit_network_sale,
                },
                ...stateKpis,
            };
        }
        case SpvActions.SET_NETWORK_SALE: {
            const { shouldDeleteBattery, network_sale, isDefault } = action.payload;
            const { inputs, hasAutoUpdateKPIs, hasUpdatedKPIS } = state;

            let stateKpis = {};
            if (!isDefault && (!hasAutoUpdateKPIs || hasUpdatedKPIS)) {
                stateKpis = {
                    bundleSims: null,
                    simulations: null,
                    standaloneSims: null,
                    productions: null,
                    hasProduction: false,
                    hasUpdatedKPIS: false,
                    isComputeKPIs: false,
                };
            }

            return {
                ...state,
                inputs: {
                    ...inputs,
                    network_sale,
                    // If the battery isn't hybrid, we need to remove it
                    battery: shouldDeleteBattery ? null : inputs?.battery,
                },
                ...stateKpis,
                tags: {
                    ...state.tags,
                    info: shouldDeleteBattery ? state.tags.info.filter((tag) => !tag?.battery) : state.tags.info,
                },
            };
        }
        case SpvActions.SET_INJECTION_TARIFF: {
            const { injection_tariff } = action.payload;

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: {
                    ...state.inputs,
                    injection_tariff,
                },
            };
        }
        case SpvActions.SET_REMUNERATION_MODEL: {
            const {
                newInputs,
                options: { batteryConditions },
            } = action.payload;

            return {
                ...state,
                ...getKPIsOnDemand(state),
                tags: {
                    ...state.tags,
                    info: batteryConditions ? state.tags.info.filter((tag) => !tag?.battery) : state.tags.info,
                },
                inputs: newInputs,
                loadings: {
                    ...state.loadings,
                    simulation: true,
                },
                bundleSims: batteryConditions ? null : state.bundleSims,
            };
        }
        case SpvActions.SET_BATTERY: {
            const { newInputs } = action.payload;
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
            };
        }
        case SpvActions.DELETE_BATTERY: {
            const { newInputs } = action.payload;

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                tags: {
                    ...state.tags,
                    info: state.tags.info.filter((tag) => !tag?.battery),
                },
                bundleSims: null,
            };
        }
        case SpvActions.SET_BATTERY_POWERS: {
            const { batteryPowers } = action.payload;
            return {
                ...state,
                ...getKPIsOnDemand(state),
                options: {
                    ...state.options,
                    batteryPowers: batteryPowers,
                },
            };
        }
        case SpvActions.SET_COMPLETE_BATTERY_POWERS: {
            const { completeBatteryPowers } = action.payload;
            return {
                ...state,
                options: {
                    ...state.options,
                    completeBatteryPowers: completeBatteryPowers,
                },
            };
        }
        case SpvActions.SET_RANGES: {
            return {
                ...state,
                ranges: action.payload.ranges,
            };
        }
        case SpvActions.SET_INVERTERS_LIST: {
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inverters: {
                    ...state.inverters,
                    list: action.payload.inverters,
                },
            };
        }
        case SpvActions.SET_INVERTERS_LIST_WITH_COSTS: {
            return {
                ...state,
                inverters: {
                    ...state.inverters,
                    listWithCosts: action.payload.invertersWithCosts,
                },
            };
        }
        case SpvActions.SET_DIALOG: {
            const dialog = Object.entries(action.payload);
            return {
                ...state,
                dialogs: {
                    ...state.dialogs,
                    [dialog[0][0]]: dialog[0][1],
                },
                shortcut: null,
            };
        }
        case SpvActions.SET_SELECT_AREA_v2: {
            return {
                ...state,
                selectedArea: action.payload.groupId,
                inputs: {
                    ...action.payload.newInputs,
                    areas: action.payload.newInputs.areas.map((area) => ({
                        ...area,
                        selected: area.id === action.payload.groupId,
                    })),
                },
            };
        }
        case SpvActions.ADD_NEW_GROUP: {
            const { polygon, newInputs } = action.payload;
            const { polygonGroups } = state;

            // Adds polygon to polygonGroups
            const _polygonGroups = [...polygonGroups, polygon];

            selectPolygon(polygon?.id, _polygonGroups, { visibilty: state.visibility });

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                polygonGroups: _polygonGroups,
                selectedArea: polygon?.id, // Sets selected area to new polygon
            };
        }
        case SpvActions.UPDATE_GROUP: {
            const {
                polygon,
                newInputs,
                options: { hasUpdatedKPIS = true },
            } = action.payload;

            const _polygonGroups = state.polygonGroups.map((_polygon) => {
                if (_polygon.id === polygon.id) return polygon;
                return _polygon;
            });
            selectPolygon(polygon?.id, _polygonGroups, { isUpdating: true, visibility: state.visibility });
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                polygonGroups: _polygonGroups,
                selectedArea: polygon?.id, // Sets selected area to new polygon
                hasUpdatedKPIS,
                shortcut: null,
            };
        }
        case SpvActions.STAMP: {
            const { polygon, newInputs } = action.payload;
            const { polygonGroups } = state;

            // Adds polygon to polygonGroups
            const _polygonGroups = [...polygonGroups, polygon];

            selectPolygon(polygon?.id, _polygonGroups, { visibility: state.visibility });

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                polygonGroups: _polygonGroups,
                selectedArea: polygon?.id, // Sets selected area to new polygon
                ...getKPIsOnDemand(state),
            };
        }
        case SpvActions.DELETE_GROUP: {
            const { idToSelect, newPolygonGroups, options, newInputs, groupId, idToDelete } = action.payload;

            // check if V2 or V3
            const deleteIndex = groupId ? groupId : idToDelete;

            return {
                ...state,
                polygonGroups: newPolygonGroups,
                inputs: newInputs,
                selectedArea: idToSelect,
                // simulations: options.resetInputs ? initialSPVProReducer.simulations : state.simulations,
                // standaloneSims: options.resetInputs ? initialSPVProReducer.standaloneSims : state.standaloneSims,
                // bundleSims: options.resetInputs ? initialSPVProReducer.bundleSims : state.bundleSims,
                tags: options.resetInputs ? initialTags : state.tags,
                usefulAreaData: state.usefulAreaData.filter((uf) => uf.id !== deleteIndex),
                ...getKPIsOnDemand(state),
            };
        }
        case SpvActions.DELETE_ALL_GROUPS: {
            const { newInputs } = action.payload;

            return {
                ...state,
                inputs: newInputs,
                polygonGroups: [],
                // simulations: initialSPVProReducer.simulations,
                // standaloneSims: initialSPVProReducer.standaloneSims,
                // bundleSims: initialSPVProReducer.bundleSims,
                tags: initialTags,
                usefulAreaData: [],
                ...getKPIsOnDemand(state),
            };
        }
        case SpvActions.SET_SELECT_GROUP: {
            return {
                ...state,
                inputs: action.payload.newInputs ?? state.inputs,
                selectedArea: action.payload.groupId,
                polygonGroups: action.payload?.polygonGroups ?? state.polygonGroups,
            };
        }
        case SpvActions.SET_CHARTS_TAG: {
            const alert = action.payload;
            if (!isDefined(alert)) {
                return {
                    ...state,
                    graphs: {
                        ...(state?.graphs ?? {}),
                        tags: {
                            consumptionProduction: [],
                            sensibilityAnalysis: [],
                        },
                    },
                };
            }
            // CONSUMPTION_PRODUCTION
            const newAlert: ISpvCharts[] = state.graphs.tags.consumptionProduction.filter(
                (item) => item.chartId === CHARTS_IDS.CONSUMPTIONS_PRODUCTIONS && item.message !== alert.message
            );
            newAlert.push(alert);
            //SENSIBILITY_ANALYSIS
            //TODO: por concluir
            // let newAlert = alertTagsOnCharts.filter(item =>
            //     (item.chartId === CHARTS_IDS.SENSIBILITY_ANALYSIS && item.data.message !== alert.data.message));
            // newAlert.push(alert);

            // setAlertTagsOnCharts(newAlert);
            return state;
        }
        case SpvActions.SET_SENSIBILITY_DATA: {
            const { sensibilityData = null, loading } = action.payload;
            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    sensibilityAnalysis: {
                        ...state.graphs.sensibilityAnalysis,
                        data: sensibilityData,
                    },
                },
                loadings: {
                    ...state.loadings,
                    sensibilityAnalysis: loading,
                },
            };
        }
        case SpvActions.SET_SENSIBILITY_DATA_BATTERY: {
            const { batterySensibilityData = null, loading } = action.payload;
            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    sensibilityAnalysis: {
                        ...state.graphs.sensibilityAnalysis,
                        batteryData: batterySensibilityData,
                    },
                },
                loadings: {
                    ...state.loadings,
                    sensibilityAnalysisBattery: loading,
                },
            };
        }
        case SpvActions.SET_VISIBLE_CHART: {
            const { chart, onDialog } = action.payload;

            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    visibleChart: onDialog ? state.graphs.visibleChart : chart,
                    visibleDialogChart: onDialog ? chart : state.graphs.visibleDialogChart,
                },
            };
        }
        case SpvActions.SET_SENSIBILITY_POINTS_TYPE: {
            const { pointsType, onDialog } = action.payload;

            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    sensibilityAnalysis: {
                        sensibilityAnalysisPointsType:
                            onDialog ? state.graphs.sensibilityAnalysis.sensibilityAnalysisPointsType : pointsType,
                        sensibilityAnalysisDialogPointsType:
                            onDialog ? pointsType : state.graphs.sensibilityAnalysis.sensibilityAnalysisDialogPointsType,
                    },
                },
            };
        }
        case SpvActions.SET_DRAWN_PANELS: {
            return {
                ...state,
                panels: {
                    ...state.panels,
                    drawn: action.payload.drawnPanels,
                },
            };
        }
        case SpvActions.SET_GROUP_TAB: {
            const {
                groupTab,
                newInputs,
                options: { updateProduct },
            } = action.payload;
            const newArea =
                groupTab === SYSTEM_SETTINGS_TABS.EQUIPMENT ? newInputs.areas.find((area) => area.selected)?.id : state.selectedArea;
            const newVisibility =
                groupTab === SYSTEM_SETTINGS_TABS.EQUIPMENT ?
                    { ...state.visibility, areas: true, panels: true }
                :   { ...state.visibility, exclusions: true };

            const newProductSelection =
                updateProduct ?
                    groupTab === SYSTEM_SETTINGS_TABS.EQUIPMENT ? SYSTEM_SETTINGS_GROUPS.PANELS
                    : state.pvOptions?.mostra_menu_exclusoes ? SYSTEM_SETTINGS_GROUPS.EXCLUSIONS
                    : SYSTEM_SETTINGS_GROUPS.BUILDINGS
                :   state.productSelection;

            setElementsVisibility(newVisibility, newProductSelection);
            return {
                ...state,
                inputs: newInputs,
                groupTab: updateProduct ? groupTab : state.groupTab,
                productSelection: newProductSelection,
                visibility: newVisibility,
                selectedArea: newArea,
            };
        }
        case SpvActions.SET_PRODUCT_SELECTION: {
            const {
                productSelection,
                newInputs,
                options: { updateProduct },
            } = action.payload;

            let newVisibility = state.visibility;

            if (productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS) {
                newVisibility = { ...newVisibility, exclusions: true };
            } else if (productSelection === SYSTEM_SETTINGS_GROUPS.BUILDINGS) {
                newVisibility = { ...newVisibility, buildings: true };
            }
            setElementsVisibility(newVisibility, updateProduct ? productSelection : state.productSelection);
            return {
                ...state,
                inputs: newInputs,
                productSelection: updateProduct ? productSelection : state.productSelection,
                visibility: newVisibility,
            };
        }
        case SpvActions.SET_PRODUCT_SELECTION_INTERSECTION: {
            const { productSelection, id, newInputs } = action.payload;
            let _selectedBuilding = clone(state.selectedBuilding);
            let _selectedArea = clone(state.selectedArea);
            let _selectedExclusion = clone(state.selectedExclusion);
            let _visibility = clone(state.visibility);
            let newGroupTab = state.groupTab;
            let newGroupSelection = state.productSelection;

            if (productSelection === SYSTEM_SETTINGS_GROUPS.PANELS) {
                _selectedArea = id;
                _visibility = { ...state.visibility, areas: true, panels: true };
                newGroupTab = SYSTEM_SETTINGS_TABS.EQUIPMENT;
                newGroupSelection = SYSTEM_SETTINGS_GROUPS.PANELS;
                for (let i = 0; i < newInputs?.areas?.length; i++) {
                    newInputs.areas[i].selected = i === id;
                }
            } else if (productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS) {
                _selectedExclusion = id;
                _visibility = { ...state.visibility, exclusions: true };
                newGroupTab = SYSTEM_SETTINGS_TABS.ENVIRONMENT;
                newGroupSelection = SYSTEM_SETTINGS_GROUPS.EXCLUSIONS;
                for (let i = 0; i < newInputs?.exclusions?.length; i++) {
                    newInputs.exclusions[i].selected = i === id;
                }
            } else if (productSelection === SYSTEM_SETTINGS_GROUPS.BUILDINGS) {
                _selectedBuilding = id;
                _visibility = { ...state.visibility, buildings: true };
                newGroupTab = SYSTEM_SETTINGS_TABS.ENVIRONMENT;
                newGroupSelection = SYSTEM_SETTINGS_GROUPS.BUILDINGS;
            }
            setElementsVisibility(_visibility, newGroupSelection);
            return {
                ...state,
                inputs: newInputs,
                productSelection: newGroupSelection,
                groupTab: newGroupTab,
                selectedArea: _selectedArea,
                selectedExclusion: _selectedExclusion,
                selectedBuilding: _selectedBuilding,
                visibility: _visibility,
            };
        }
        case SpvActions.APPLY_EXCLUSIONS_CHANGES: {
            const productSelection = action.payload.productSelectionRef?.current;
            let newVisibility = state.visibility;

            if (productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS) {
                newVisibility = { ...newVisibility, exclusions: true };
            } else if (productSelection === SYSTEM_SETTINGS_GROUPS.BUILDINGS) {
                newVisibility = { ...newVisibility, buildings: true };
            }
            setElementsVisibility(newVisibility, productSelection);

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.options.updateInputs ? action.payload.newInputs : state.inputs,
                productSelection: action.payload.productSelectionRef?.current ?? state.productSelection,
                groupTab: action.payload.groupTabRef?.current ?? state.groupTab,
                exclusionGroups: action.payload.exclusionGroups ?? state.exclusionGroups,
                selectedExclusion: 'selectedExclusion' in action.payload ? action.payload.selectedExclusion : state.selectedExclusion,
                visibility: newVisibility,
                dialogs: {
                    ...state.dialogs,
                    exclusionsConfirmation: false,
                },
            };
        }
        case SpvActions.UPDATE_EXCLUSION_GROUPS: {
            const { exclusionGroups, selectedExclusion } = action.payload;
            return {
                ...state,
                ...getKPIsOnDemand(state),
                exclusionGroups,
                selectedExclusion: 'selectedExclusion' in action.payload ? selectedExclusion : state.selectedExclusion,
            };
        }
        case SpvActions.SET_SELECT_EXCLUSION: {
            return {
                ...state,
                selectedExclusion: action.payload.exclusionId,
                exclusionGroups: action.payload?.exclusionGroups ?? state.exclusionGroups,
            };
        }
        case SpvActions.DISCARD_EXCLUSIONS_CHANGES: {
            const {
                exclusionZonesPolygons,
                setDispatchEvt,
                setHasChangesInExclusions,
                setHasChangedExcusionInputs,
                productSelectionRef,
                groupTabRef,
                isSPVv3 = false,
            } = action.payload;
            setHasChangesInExclusions(false);
            if (isSPVv3) setHasChangedExcusionInputs(() => new Map());
            !isSPVv3 &&
                onDiscardExclusionsChangesHandler(
                    exclusionZonesPolygons,
                    state.inputs.exclusions,
                    setDispatchEvt,
                    setHasChangesInExclusions
                );
            return {
                ...state,
                exclusionGroups:
                    isSPVv3 ? onDiscardExclusionsChanges(state.exclusionGroups, state.inputs.exclusions) : state.exclusionGroups,
                productSelection: productSelectionRef.current,
                groupTab: groupTabRef?.current ?? state.groupTab,
                dialogs: {
                    ...state.dialogs,
                    exclusionsConfirmation: false,
                },
            };
        }
        case SpvActions.UPDATE_BUILDING_GROUPS: {
            if (state.isSimpleMode) {
                return {
                    ...state,
                    simpleMode: {
                        ...state.simpleMode,
                        inputs: action.payload.newSimpleModeInputs,
                        buildingGroups: action.payload.buildingGroups,
                    },
                    ...getKPIsOnDemand(state),
                    inputs: action.payload.newInputs,
                    buildingGroups: action.payload.buildingGroups,
                    selectedBuilding: 'selectedBuilding' in action.payload ? action.payload.selectedBuilding : state.selectedBuilding,
                };
            }
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.newInputs,
                buildingGroups: action.payload.buildingGroups,
                selectedBuilding: 'selectedBuilding' in action.payload ? action.payload.selectedBuilding : state.selectedBuilding,
                shortcut: null,
            };
        }
        case SpvActions.UPDATE_BUILDINGS: {
            const { newInputs } = action.payload;
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
            };
        }
        case SpvActions.UPDATE_BUILDINGS_DS: {
            const { newInputs } = action.payload;

            const newBuildingGroups: Polygon[] = [];
            newInputs.buildings.forEach((building, index) => {
                const buildingPolygon = buildDefaultExclusionPolygon(building.coordinates, index === 0, newBuildingGroups, {
                    id: building.id,
                    draggable: false,
                    editable: true,
                    center: building?.centroid,
                    zIndex: 20,
                });
                newBuildingGroups.push(buildingPolygon);
                buildDefaultAreaMeshFromPolygon(buildingPolygon, { threejsOverlay: null, scene: null }, true, MESH_TYPES.BUILDING);
            });

            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                buildingGroups: newBuildingGroups,
                selectedBuilding: newBuildingGroups?.[0]?.id,
            };
        }
        case SpvActions.SET_SELECT_BUILDING: {
            return {
                ...state,
                selectedBuilding: action.payload.selectedBuilding,
                buildingGroups: action.payload?.buildingGroups ?? state.buildingGroups,
            };
        }
        case SpvActions.SET_SLOPES: {
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.newInputs,
                dialogs: {
                    ...state.dialogs,
                    editSlope: false,
                },
            };
        }
        case SpvActions.SET_ORIENTATION: {
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.newInputs,
                dialogs: {
                    ...state.dialogs,
                    editOrientation: null,
                },
                hasUpdatedKPIS: false,
                hasOrientationChanged: true,
            };
        }
        case SpvActions.SET_MOUNTING_STRUCTURE_ID: {
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.newInputs,
                dialogs: {
                    ...state.dialogs,
                    editStructure: null,
                },
            };
        }
        case SpvActions.SET_USEFUL_AREA: {
            const { usefulAreaAction, ...options } = action.payload;
            const usefulArea = clone(state.usefulAreaData);
            const newUsefullArea = usefulAreaHandler(usefulArea, usefulAreaAction, {
                ...options,
                areas: clone(state.inputs.areas),
                onProject: state.loadings.loadProject,
            });

            const oldPanelsNumber = usefulArea.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.panels_number;
            }, 0);

            const newPanelsNumber = newUsefullArea.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.panels_number;
            }, 0);

            return {
                ...state,
                hasUpdatedKPIS: oldPanelsNumber === newPanelsNumber,
                usefulAreaData: newUsefullArea,
            };
        }
        case SpvActions.SET_INVERTERS: {
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: action.payload.newInputs,
                inverters: {
                    ...state.inverters,
                    combination: action.payload.newInputs?.inverters_combination,
                },
                dialogs: {
                    ...state.dialogs,
                    inverters: null,
                },
            };
        }
        case SpvActions.SET_PRODUCTION_LOSSES: {
            return {
                ...state,
                inputs: action.payload.newInputs,
                dialogs: {
                    ...state.dialogs,
                    productionLosses: initialOpenDialogs.productionLosses,
                },
            };
        }
        case SpvActions.SET_MAP_PRINT_STATE: {
            const { mapPrintState } = action.payload;
            return {
                ...state,
                mapPrintState,
            };
        }
        case SpvActions.SET_INVERTERS_COMBINATION: {
            const { inverters } = action.payload;

            return {
                ...state,
                inverters: {
                    ...state.inverters,
                    combination: inverters?.selected ?? [],
                    recomendedCombination: inverters?.recommended ?? [],
                    hasInverters: isDefined(inverters?.selected),
                },
                loadings: {
                    ...state.loadings,
                    inverters: false,
                },
            };
        }
        case SpvActions.SET_IS_SIMPLE_MODE: {
            const { isSimpleMode, newInputs } = action.payload;

            if (!isSimpleMode) {
                return {
                    ...state,
                    simpleMode: {
                        ...state.simpleMode,
                        ...SIMPLE_MODE_DEFAULT,
                        facilityCoordinates: {
                            ...state.simpleMode.facilityCoordinates,
                        },
                        inputs: {
                            ...state.simpleMode.inputs,
                            areas: [],
                            exclusions: [],
                            total_areas: 0,
                            total_panels: 0,
                            inverters_combination: [],
                            coordinates_conversion_factor: null,
                        },
                        step: SIMPLE_MODE_STEPS.ADDRESS,
                    },
                    inputs: {
                        ...state.inputs,
                        ...newInputs,
                        inverters_combination: [],
                        buildings: [], // passar só os necessários.
                    },
                    buildingGroups: [],
                    doInitFetch: state.doInitFetch + 1,
                    isSimpleMode,
                    productSelection: SYSTEM_SETTINGS_GROUPS.PANELS,
                    groupTab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
                    visibility: DEFAULT_VISIBILITY_ELEMENTS.panels,
                    selectedArea: 1,
                };
            }
            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    step: SIMPLE_MODE_STEPS.ADDRESS,
                    inputs: {
                        ...state.simpleMode.inputs,
                        buildings: [],
                    },
                },
                inputs: newInputs,
                exclusionGroups: [],
                polygonGroups: [],
                buildingGroups: [],
                isSimpleMode,
                doInitFetch: 0,
                visibility: DEFAULT_VISIBILITY_ELEMENTS.buildings,
            };
        }
        case SpvActions.SET_SIMPLE_MODE_STEP: {
            const { stepToGo, roofType, newSimpleModeInputs } = action.payload;
            const manualDrawing = stepToGo === SIMPLE_MODE_STEPS.DRAW_ROOF_MANUALLY ? true : state.simpleMode.isManualDrawing;
            const alertManualDrawing =
                state.simpleMode.step === SIMPLE_MODE_STEPS.KPIS_DISPLAY && stepToGo === SIMPLE_MODE_STEPS.ROOF_DETAILS ?
                    true
                :   !manualDrawing;
            let roof = state.simpleMode.roof;
            if (state.isB2C && stepToGo === SIMPLE_MODE_STEPS.ROOF_DETAILS && !manualDrawing) {
                roof = action.payload?.roof ?? state.simpleMode.roof;
            }

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    inputs: newSimpleModeInputs,
                    step: stepToGo,
                    isManualDrawing: manualDrawing,
                    showAlertManualDrawing: alertManualDrawing,
                    roof,
                    roofType: roofType ?? state.simpleMode.roofType,
                    polygonGroups: stepToGo === SIMPLE_MODE_STEPS.ROOF_DETAILS ? [] : state.simpleMode.polygonGroups,
                    exclusionGroups: stepToGo === SIMPLE_MODE_STEPS.ROOF_DETAILS ? [] : state.simpleMode.exclusionGroups,
                },
            };
        }
        case SpvActions.SET_SIMPLE_MODE_ROOF_IDENTIFICATION: {
            const { stepToGo, roofTypeStructures, roofType: roofTypeDefault, roof } = action.payload;
            let roofType = state?.simpleMode?.roofType;

            //define default roofType if it doesn't exist
            if (!roofType?.default?.roof_type_id) {
                roofType = {
                    ...roofTypeDefault,
                    default: { ...roofTypeDefault },
                };
            }

            //change roofType to default if the roof is the same as the default
            if (JSON.stringify(roof) !== JSON.stringify(state.simpleMode.roof)) {
                roofType = {
                    ...roofTypeDefault,
                    default: { ...roofTypeDefault },
                };
            }

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    step: stepToGo,
                    roof: roof ?? state.simpleMode.roof,
                    roofType,
                    roofTypeStructures,
                    loadings: {
                        ...state.loadings,
                        ...action.payload?.loadings.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
                    },
                },
            };
        }
        case SpvActions.RESET_SIMPLE_MODE: {
            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    ...SIMPLE_MODE_DEFAULT,
                    inputs: {
                        ...state.simpleMode.inputs,
                        ...state.defaultInputs,
                    },
                    facilityCoordinates: {
                        ...state.simpleMode.facilityCoordinates,
                    },
                    step: SIMPLE_MODE_STEPS.ADDRESS,
                    polygonGroups: [],
                    exclusionGroups: [],
                    buildingGroups: [],
                },
                buildingGroups: [],
            };
        }
        case SpvActions.SET_SIMPLE_SIMULATIONS: {
            const { inputs, kpis } = action.payload;
            const injection_tariff =
                [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_ES].includes(Number(state.companyProfileId)) ?
                    kpis?.injection_tariff
                :   inputs?.injection_tariff;

            const exclusionGroups = [] as Polygon[];
            const polygonGroups = [] as Polygon[];
            inputs.areas.forEach((area, areaIndex) => {
                const isPolygonSelected = areaIndex === 0;
                const polygon = buildDefaultPolygon(area.coordinates, isPolygonSelected, [], {
                    id: area.id,
                    draggable: true,
                    editable: true,
                    center: area?.centroid,
                });
                polygonGroups.push(polygon);
                area.selected = isPolygonSelected;
            });

            if (inputs.exclusions?.length > 0) {
                inputs.exclusions.forEach((exclusion) => {
                    // const isExclusionSelected = exclusionIndex === LAST_ADDED_AREA_INDEX;
                    const exclusionArea = buildDefaultExclusionPolygon(
                        exclusion.coordinates.map((c) => new LatLng(c[0], c[1])),
                        false,
                        [],
                        {
                            id: exclusion.id,
                            draggable: false,
                            editable: true,
                            center: exclusion?.centroid,
                            zIndex: 20,
                            height: exclusion?.height ?? 0,
                        }
                    );
                    exclusionGroups.push(exclusionArea);
                    exclusion.selected = false;
                });
                // polygonGroups will never have items b4 this SET_INIT
                // so we can say that the selectedAreaId will be the same as the polygonGroups[LAS_ADDED_AREA_INDEX].id
            }

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    inputs: {
                        ...state.simpleMode.inputs,
                        ...inputs,
                        injection_tariff,
                    },
                    simulationKpis: kpis,
                    polygonGroups: polygonGroups,
                    exclusionGroups: exclusionGroups,
                },
                inputs: {
                    ...inputs,
                    injection_tariff,
                },
                polygonGroups,
                exclusionGroups,
                simulations: kpis,
                standaloneSims: kpis,
                bundleSims: null,
                loadings: {
                    ...state.loadings,
                    [SPV_LOADINGS.SIMULATIONS]: false,
                    [SpvLoadings.spvSimpleKpisRequests]: false,
                },
                hasUpdatedKPIS: true,
                isComputeKPIs: false,
            };
        }
        case SpvActions.SET_SELECTED_ROOF_TYPE_ID: {
            const { roof_type_id, roof_type } = action.payload;

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    roofType: {
                        ...state.simpleMode.roofType,
                        roof_type_id,
                        roof_type,
                    },
                },
            };
        }

        case SpvActions.SET_SIMPLE_ROOF_STRUCTURES: {
            const { roofTypeStructures } = action.payload;

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    roofTypeStructures,
                },
            };
        }
        case SpvActions.SET_SIMPLE_ROOF_POLYGON: {
            const { polygon } = action.payload;

            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    roofPolygon: polygon,
                },
            };
        }
        case SpvActions.APPLY_SIMPLE_AUTO_SPLIT_FINAL: {
            const { polygons, exclusionGroups } = action.payload;
            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    exclusionGroups,
                    polygonGroups: polygons,
                },
            };
        }
        case SpvActions.SET_SIMPLE_FACILITY_COORDINATES: {
            const { lat, lng } = action.payload;
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    [SpvLoadings.changeAddress]: false,
                },
                simpleMode: {
                    ...state.simpleMode,
                    facilityCoordinates: {
                        lat,
                        lng,
                    },
                },
            };
        }
        case SpvActions.APPLY_AUTO_SPLIT_FINAL: {
            const { polygons, newInputs, exclusionGroups } = action.payload;
            const { polygonGroups } = state;

            // Adds polygon to polygonGroups
            const _polygonGroups = [...polygonGroups, ...polygons];

            selectPolygon(polygons?.[0]?.id, _polygonGroups, { visibilty: state.visibility });
            setElementsVisibility(DEFAULT_VISIBILITY_ELEMENTS.panels, SYSTEM_SETTINGS_GROUPS.PANELS);
            return {
                ...state,
                ...getKPIsOnDemand(state),
                inputs: newInputs,
                polygonGroups: _polygonGroups,
                selectedArea: polygons?.[0]?.id, // Sets selected area to new polygon
                exclusionGroups: exclusionGroups,
                productSelection: SYSTEM_SETTINGS_GROUPS.PANELS,
                groupTab: SYSTEM_SETTINGS_TABS.EQUIPMENT,
                dialogs: {
                    ...state.dialogs,
                    autoSplit: false,
                },
                loadings: {
                    ...state.loadings,
                    autoSplit: false,
                },
                visibility: DEFAULT_VISIBILITY_ELEMENTS.panels,
            };
        }
        case SpvActions.PANEL_EDITOR: {
            const { newInputs } = action.payload;
            return {
                ...state,
                inputs: newInputs,
                modes: initialOverlayModes,
            };
        }
        case SpvActions.SET_PROJECT: {
            return {
                ...state,
                project: action.payload,
            };
        }
        case SpvActions.START_LOADING_PROJECT: {
            return {
                ...state,
                polygonGroups: [],
                selectedArea: null,
                exclusionGroups: [],
                selectedExclusion: null,
                buildingGroups: [],
                selectedBuilding: null,
                loadings: {
                    loadProject: true,
                },
            };
        }
        case SpvActions.RESET_PROJECT: {
            const { newInputs } = action.payload;

            return {
                ...state,
                inputs: newInputs,
                polygonGroups: [],
                selectedArea: null,
                exclusionGroups: [],
                selectedExclusion: null,
                buildingGroups: [],
                selectedBuilding: null,
                // simulations: initialSPVProReducer.simulations,
                // standaloneSims: initialSPVProReducer.standaloneSims,
                // bundleSims: initialSPVProReducer.bundleSims,
                tags: initialTags,
                usefulAreaData: [],
                ...getKPIsOnDemand(state),
            };
        }
        case SpvActions.EDIT_PROJECT_DETAILS: {
            return {
                ...state,
                project: {
                    ...state.project,
                    description: action.payload.description,
                    comments: action.payload?.comments ?? '',
                },
            };
        }
        case SpvActions.SAVE_CUSTOMIZED_PROJECT: {
            return {
                ...state,
                project: {
                    ...state.project,
                    inputs: {
                        ...state.project?.inputs,
                        customized: action.payload,
                    },
                },
            };
        }
        case SpvActions.SET_VISIBILITY_ELEMENT: {
            const { element, visibility } = action.payload;
            const newVisibilityElements = {
                ...state.visibility,
                [element]: visibility,
            };

            setElementsVisibility(newVisibilityElements, state.productSelection);
            return {
                ...state,
                visibility: newVisibilityElements,
            };
        }
        case SpvActions.SET_IS_REQUEST_ROOF_FAIL: {
            const { isRequestRoofFail } = action.payload;
            return {
                ...state,
                simpleMode: {
                    ...state.simpleMode,
                    isRequestRoofFail,
                },
            };
        }
        case SpvActions.SET_SELECTED_LAYER_NAME: {
            const { selectedLayerName } = action.payload;

            return {
                ...state,
                selectedLayerName,
            };
        }
        case SpvActions.SET_ARE_LAYERS_DOWNLOADED: {
            const { areLayersDownloaded } = action.payload;

            return {
                ...state,
                mapOptions: {
                    ...state.mapOptions,
                    areLayersDownloaded,
                },
            };
        }
        case SpvActions.GET_GSOLAR_LAYERS: {
            const { selectedLayerName } = action.payload;

            return {
                ...state,
                mapOptions: {
                    ...state.mapOptions,
                    selectedLayerName: selectedLayerName,
                },
                loadings: {
                    ...state.loadings,
                    loadLayers: false,
                },
            };
        }
        case SpvActions.SET_MAP_SELECTED_LAYER: {
            const { selectedLayerName } = action.payload;

            return {
                ...state,
                mapOptions: {
                    ...state.mapOptions,
                    selectedLayerName,
                },
            };
        }
        case SpvActions.SET_MAP_LABELS: {
            const { labels } = action.payload;

            return {
                ...state,
                mapOptions: {
                    ...state.mapOptions,
                    labels,
                },
            };
        }
        default: {
            return state;
        }
    }
};
