import { StepQuestions, TFormQuestions, TFormQuestionsSettings, THPFastForm, THPFastStepsConfig } from 'interfaces/products/hp/fast/form';
import { THPFastLoadings } from 'interfaces/products/hp/fast/index';

// Assets
// import { ReactComponent as EditIcon } from 'assets/images/products/hp/edit-step.svg';
// import { ReactComponent as EyeIcon } from 'assets/images/products/hp/eye-step.svg';

// Components
import { UNITS_AVAILABLE } from 'components/util/CustomUnit';

// Constants
import { PRODUCT_IDS } from 'constants/products';
import { USERS_TYPE_ID } from 'constants/user';

// Services
import { getCompanyProfileIds } from 'services/user';
import validate from 'services/util/validate';
import { accessObjectProp } from 'services/util/auxiliaryUtils';

// #region form input names

export const INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY_PERSONALIZABLE = {
    property_type: 'property_type',
    property_age: 'property_age',
    insulation_walls: 'walls_insulation',
    insulation_loft: 'loft_insulation',
    insulation_windows: 'windows_insulation',
    insulation_doors: 'doors_insulation',
} as const;

export const INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY = {
    ...INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY_PERSONALIZABLE,
    property_area: 'property_area',
    combination_id: 'answer_combination.id',
} as const;

export const INPUT_NAMES_STEP_PROPERTY = {
    heat_loss_calculation_method: 'heat_loss_calculation_mode_id',
    space_heating_energy_consumption: 'space_heating_energy_cons',
    impact_loft_insulation: 'insulation_impact.loft',
    impact_cavity_wall_insulation: 'insulation_impact.cavity_wall',
    impact_solid_wall_insulation: 'insulation_impact.solid_wall',
    estimated_heat_loss: 'estimated_heat_loss',
    heat_source: 'has_heat_source',
    heat_source_type: 'heat_source.type_id',
    heat_source_price_value: 'heat_source.price.value',
    heat_source_price_unit: 'heat_source.price.unit',
    heat_source_age: 'heat_source.age_id',

    manual_heat_loss: 'heat_loss',
    ...INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY,
} as const;

export const INPUT_NAMES_STEP_HP_SELECTION = {
    equipment_id: 'equipment_id',
    equipment_cost: 'equipment_cost',
    heatsource_dt: 'heatsource_dt',
    flow_temperature: 'flow_temp',
    return_temperature: 'return_temperature',
} as const;

export const INPUT_NAMES_STEP_EXTRAS = {
    has_domestic_hot_water_tank: 'has_domestic_hot_water_tank',
    hot_water_per_occupant: 'l_per_day_per_occupant',
    instrumentations: 'instrumentations',
    n_occupants: 'nr_occupants',
    size: 'size',
    quantity: 'quantity',
    cat_id: 'cat_id',
    model_id: 'model_id',
} as const;

export const INPUT_NAMES_EXTRA = {
    cost: 'cost',
    deposit_l: 'deposit_l',
    description: 'description',
    hp_compatible_id_arr: 'hp_compatible_id_arr',
    id: 'id',
    cat_id: 'cat_id',
    model_id: 'model_id',
    calc_mode_id: 'calc_mode_id',
} as const;

// #endregion form input names

// #region Limits / Constrains

// #region step property
export const SPACE_HEATING_ENERGY_CONSUMPTION_LIMITS = {
    min: 0,
    max: 100000,
} as const;

export const IMPACT_LOFT_INSULATION_LIMITS = {
    min: 0,
    max: 100000,
} as const;

export const IMPACT_CAVITY_WALL_INSULATION_LIMITS = {
    min: 0,
    max: 100000,
} as const;

export const IMPACT_SOLID_WALL_INSULATION_LIMITS = {
    min: 0,
    max: 100000,
} as const;

export const PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS = {
    min: 2,
    max: 50,
} as const;

export const PROPERTY_DEFINE_PROPERTY_AREA_LIMITS = {
    min: 0,
    max: 1000,
} as const;

// #endregion step property

// #region step hp sellection

export const HEATSOURCE_DT_LIMITS = {
    min: 0,
    max: 20,
    recomendedMin: 5,
    recomandedMax: 10,
} as const;

export const FLOW_TEMPERATURE_LIMITS = {
    min: 18,
    max: 55,
} as const;

// #endregion step hp sellection

// #endregion Limits / Constrains

// #region Answer options
export const HEAT_LOSS_CALCULATION_METHOD = {
    EPC_DATA: 1,
    INSERT_MANUALLY: 2,
    DEFINE_PROPERTY: 3,
} as const;

export const HEAT_SOURCE_TYPES_IDS = {
    GAS: 1,
    OIL: 2,
    LPG: 3,
    ELECTRIC: 4,
} as const;

export const HEAT_SOURCE_PRICE_UNITS = {
    CURR_KWH: UNITS_AVAILABLE.CKWH,
    CURR_CM: UNITS_AVAILABLE.CCM,
    CURR_LITER: UNITS_AVAILABLE.CL,
} as const;

export const EXTRAS_CAT_IDS = {
    DHW: 1,
    THERMOSTAT: 4,
    CIRCULATION_PUMP: 6,
    OTHERS: 7,
} as const;

export const DHW_CALC_MODE_IDS = {
    MANUALLY: 1,
    CALCULATE: 2,
} as const;

// #endregion Answer options

// #region State management

export const HP_FAST_LOADINGS = {
    MOUNTING: 'mounting',
    GENERAL: 'general',
    NEXT: 'next',
    SIMULATION: 'simulation',
    SAVE: 'save',
} as const;

export enum HP_FAST_ACTIONS {
    SET_MOUNT_DATA = 'SET_MOUNT_DATA',
    SET_LOADINGS = 'SET_LOADINGS',
    SET_KPIS = 'SET_KPIS',
    RESET_KPIS = 'RESET_KPIS',
    SET_INPUTS = 'SET_INPUTS',
    SET_GLOBAL_DIALOG_STATE = 'SET_GLOBAL_DIALOG_STATE',
    NAVIGATE = 'NAVIGATE',
    SET_BUSINESS_MODELS = 'SET_BUSINESS_MODELS',
    RESET = 'RESET',
    SET_EXTRA_EQUIPMENT_CAT_MODEL = 'SET_EXTRA_EQUIPMENT_CAT_MODEL',
}

export const DEFAULT_LOADINGS: THPFastLoadings = {
    mounting: true,
    general: false,
    next: false,
    simulation: false,
    save: false,
};

// #endregion State management

// #region form constants

export const HP_FAST_FORM_ID = 'efz-HP-fast-form';

export enum HP_FAST_STEPS {
    PROPERTY = 'property',
    HP_SELECTION = 'hp_selection',
    EXTRAS = 'extras',
    BILL_OF_MATERIALS = 'bill_of_materials',
    PROPOSAL = 'proposal',
}

export const HP_FAST_SUB_STEPS = {
    PROPOSAL_BUSINESS_MODELS: 'business_models',
    PROPOSAL_DOWNLOAD: 'proposal_download',
} as const;

export const HP_FAST_INPUT_NAMES = {
    [HP_FAST_STEPS.PROPERTY]: {
        ...INPUT_NAMES_STEP_PROPERTY,
    },
    [HP_FAST_STEPS.HP_SELECTION]: {
        ...INPUT_NAMES_STEP_HP_SELECTION,
    },
    [HP_FAST_STEPS.EXTRAS]: {
        ...INPUT_NAMES_STEP_EXTRAS,
    },
} as const;

export const HP_FAST_DEFAULT_FORM_VALUES = {
    [HP_FAST_STEPS.PROPERTY]: {
        [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY,
    },
    [HP_FAST_STEPS.HP_SELECTION]: {
        [INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt]: 5,
        [INPUT_NAMES_STEP_HP_SELECTION.flow_temperature]: 45,
    },
    [HP_FAST_STEPS.EXTRAS]: {
        [INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank]: false,
        [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]: 45,
    },
};

export const HP_FAST_DEFAULT_RESET_FORM_VALUES = {
    [HP_FAST_STEPS.PROPERTY]: {
        [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY,
        [INPUT_NAMES_STEP_PROPERTY.heat_source]: '',
        [INPUT_NAMES_STEP_PROPERTY.property_area]: '',
    },
    [HP_FAST_STEPS.HP_SELECTION]: {
        [INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt]: 5,
        [INPUT_NAMES_STEP_HP_SELECTION.flow_temperature]: 45,
    },
    [HP_FAST_STEPS.EXTRAS]: {
        [INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank]: false,
        [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]: 45,
    },
};

export const HP_FAST_DEFAULT_STEPPER_STATE = {
    [HP_FAST_STEPS.PROPERTY]: {
        disabled: false,
    },
    [HP_FAST_STEPS.HP_SELECTION]: {
        disabled: true,
    },
    [HP_FAST_STEPS.EXTRAS]: {
        disabled: true,
    },
    [HP_FAST_STEPS.BILL_OF_MATERIALS]: {
        disabled: true,
    },
    [HP_FAST_STEPS.PROPOSAL]: {
        disabled: true,
    },
};

export const HP_FAST_STEPS_CONFIG: THPFastStepsConfig = {
    [HP_FAST_STEPS.PROPERTY]: {
        label: 'page.hp.step.1',
        value: HP_FAST_STEPS.PROPERTY,
        visible: true,
        disabled: false,
        icon: 'editPencil',
        dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.PROPERTY}`,
    },
    [HP_FAST_STEPS.HP_SELECTION]: {
        label: 'page.hp.step.3',
        value: HP_FAST_STEPS.HP_SELECTION,
        visible: true,
        disabled: false,
        icon: 'editPencil',
        dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.HP_SELECTION}`,
    },
    [HP_FAST_STEPS.EXTRAS]: {
        label: 'page.hp.step.4',
        value: HP_FAST_STEPS.EXTRAS,
        visible: true,
        disabled: false,
        icon: 'editPencil',
        dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.EXTRAS}`,
    },
    [HP_FAST_STEPS.BILL_OF_MATERIALS]: {
        label: 'page.hp.step.7',
        value: HP_FAST_STEPS.BILL_OF_MATERIALS,
        visible: true,
        disabled: false,
        icon: 'viewEye',
        dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.BILL_OF_MATERIALS}`,
    },
    [HP_FAST_STEPS.PROPOSAL]: {
        label: 'page.hp.step.8',
        value: HP_FAST_STEPS.PROPOSAL,
        visible: true,
        disabled: false,
        subSteps: {
            [HP_FAST_SUB_STEPS.PROPOSAL_BUSINESS_MODELS]: {
                label: 'page.hp.step.8.1',
                value: HP_FAST_SUB_STEPS.PROPOSAL_BUSINESS_MODELS,
                visible: true,
                disabled: false,
                icon: 'editPencil',
                dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.PROPOSAL}-${HP_FAST_SUB_STEPS.PROPOSAL_BUSINESS_MODELS}`,
            },
            [HP_FAST_SUB_STEPS.PROPOSAL_DOWNLOAD]: {
                label: 'page.hp.step.8.2',
                value: HP_FAST_SUB_STEPS.PROPOSAL_DOWNLOAD,
                visible: true,
                disabled: false,
                icon: 'editPencil',
                dataTestId: `hp-stepper-btnStep-${HP_FAST_STEPS.PROPOSAL}-${HP_FAST_SUB_STEPS.PROPOSAL_BUSINESS_MODELS}`,
            },
        },
    },
} as const;

export const HP_FAST_STEP_PROPERTY_QUESTIONS: StepQuestions<HP_FAST_STEPS.PROPERTY> = {
    [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method,
        label: 'page.hp.property.heat_loss_calculation_mode_id',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'label-radio',
        validation: validate(['required']),
        options: [
            {
                value: HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY,
                label: {
                    label: 'page.hp.property.heat_loss_calculation_mode_id.defineProperty',
                },
            },
            {
                value: HEAT_LOSS_CALCULATION_METHOD.EPC_DATA,
                label: {
                    label: 'page.hp.property.heat_loss_calculation_mode_id.epcData',
                },
            },
            {
                value: HEAT_LOSS_CALCULATION_METHOD.INSERT_MANUALLY,
                label: {
                    label: 'page.hp.property.heat_loss_calculation_mode_id.manual',
                },
            },
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption]: {
        name: INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption,
        label: 'page.hp.property.space_heating_energy_consuption',
        placeholder: 'page.hp.property.space_heating_energy_consuption.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.YKWH,
        validation: validate(['required']),
        validationDependencies: [
            INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation,
            INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation,
            INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation,
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation]: {
        name: INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation,
        label: 'page.hp.property.impact_loft_insulation',
        placeholder: 'page.hp.property.impact_loft_insulation.placeholder',
        tooltip: {
            label: 'page.hp.property.impact_loft_insulation.tooltip',
        },
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.KWH,
        validation: {},
        validationDependencies: [
            INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption,
            INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation,
            INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation,
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation]: {
        name: INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation,
        label: 'page.hp.property.impact_cavity_wall_insulation',
        placeholder: 'page.hp.property.impact_cavity_wall_insulation.placeholder',
        tooltip: {
            label: 'page.hp.property.impact_loft_insulation.tooltip',
        },
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.KWH,
        validation: {},
        validationDependencies: [
            INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption,
            INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation,
            INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation,
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation]: {
        name: INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation,
        label: 'page.hp.property.impact_solid_wall_insulation',
        placeholder: 'page.hp.property.impact_solid_wall_insulation.placeholder',
        tooltip: {
            label: 'page.hp.property.impact_loft_insulation.tooltip',
        },
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.KWH,
        validation: {},
        validationDependencies: [
            INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption,
            INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation,
            INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation,
        ],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.heat_source]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_source,
        label: 'page.hp.property.heatSource',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'radio-label',
        validation: validate(['required']),
        options: {
            labelYes: 'label.yes',
            labelNo: 'label.no',
            valueYes: true,
            valueNo: false,
        },
        valueType: 'boolean',
    },
    [INPUT_NAMES_STEP_PROPERTY.heat_source_type]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_source_type,
        label: 'page.hp.property.heatSource.type',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
        info: {
            visible: false,
            isComponent: true,
            component: <></>,
        },
    },
    [INPUT_NAMES_STEP_PROPERTY.heat_source_price_value]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_source_price_value,
        label: 'page.hp.property.heatSource.price',
        placeholder: 'page.hp.property.heatSource.price.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit,
        label: '',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [
            {
                label: '',
                value: HEAT_SOURCE_PRICE_UNITS.CURR_CM,
                valueAsCustomUnit: true,
            },
            {
                label: '',
                value: HEAT_SOURCE_PRICE_UNITS.CURR_KWH,
                valueAsCustomUnit: true,
            },
            {
                label: '',
                value: HEAT_SOURCE_PRICE_UNITS.CURR_LITER,
                valueAsCustomUnit: true,
            },
        ],
        valueType: 'string',
    },
    [INPUT_NAMES_STEP_PROPERTY.heat_source_age]: {
        name: INPUT_NAMES_STEP_PROPERTY.heat_source_age,
        label: 'page.hp.property.heatSource.age',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
        info: {
            visible: false,
            isComponent: true,
            component: <></>,
        },
    },
    [INPUT_NAMES_STEP_PROPERTY.manual_heat_loss]: {
        name: INPUT_NAMES_STEP_PROPERTY.manual_heat_loss,
        label: 'page.hp.property.manual_heat_loss',
        placeholder: 'page.hp.property.manual_heat_loss.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.KW,
        validation: validate(['required']),
        valueType: 'number',
        info: {
            label: {
                label: 'page.hp.property.manual_heat_loss.info',
                values: {
                    min: PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS.min,
                    max: PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS.max,
                },
            },
        },
    },
    [INPUT_NAMES_STEP_PROPERTY.property_type]: {
        name: INPUT_NAMES_STEP_PROPERTY.property_type,
        label: 'page.hp.property.property_type',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.property_age]: {
        name: INPUT_NAMES_STEP_PROPERTY.property_age,
        label: 'page.hp.property.property_age',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.property_area]: {
        name: INPUT_NAMES_STEP_PROPERTY.property_area,
        label: 'page.hp.property.property_area',
        placeholder: 'page.hp.property.property_area.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        unit: UNITS_AVAILABLE.SM,
        validation: validate(['required']),
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.insulation_walls]: {
        name: INPUT_NAMES_STEP_PROPERTY.insulation_walls,
        label: 'page.hp.property.walls_insulation',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.insulation_loft]: {
        name: INPUT_NAMES_STEP_PROPERTY.insulation_loft,
        label: 'page.hp.property.loft_insulation',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.insulation_windows]: {
        name: INPUT_NAMES_STEP_PROPERTY.insulation_windows,
        label: 'page.hp.property.windows_insulation',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_PROPERTY.insulation_doors]: {
        name: INPUT_NAMES_STEP_PROPERTY.insulation_doors,
        label: 'page.hp.property.doors_insulation',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        options: [],
        valueType: 'number',
    },
};

export const HP_FAST_STEP_HP_SELECTION_QUESTIONS: StepQuestions<HP_FAST_STEPS.HP_SELECTION> = {
    [INPUT_NAMES_STEP_HP_SELECTION.equipment_id]: {
        name: INPUT_NAMES_STEP_HP_SELECTION.equipment_id,
        label: '',
        placeholder: '',
        disabled: false,
        visible: false,
        type: 'number',
        validation: {},
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_HP_SELECTION.flow_temperature]: {
        name: INPUT_NAMES_STEP_HP_SELECTION.flow_temperature,
        label: 'page.hp.selection.flow',
        placeholder: 'page.hp.selection.flow.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: {},
        valueType: 'number',
        unit: UNITS_AVAILABLE.DGC,
    },
    [INPUT_NAMES_STEP_HP_SELECTION.return_temperature]: {
        name: INPUT_NAMES_STEP_HP_SELECTION.return_temperature,
        label: 'page.hp.selection.return',
        placeholder: 'page.hp.selection.return.placeholder',
        disabled: true,
        visible: true,
        type: 'number',
        validation: {},
        valueType: 'number',
        unit: UNITS_AVAILABLE.DGC,
    },
    [INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt]: {
        name: INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt,
        label: 'page.hp.selection.heatsourceDeltaT',
        placeholder: 'page.hp.selection.heatsourceDeltaT.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: {},
        valueType: 'number',
        info: {
            label: {
                label: 'page.hp.selection.heatsourceDeltaT.alert',
                values: {
                    min: HEATSOURCE_DT_LIMITS.recomendedMin,
                    max: HEATSOURCE_DT_LIMITS.recomandedMax,
                },
            },
        },
    },
} as const;

export const HP_FAST_STEP_EXTRAS_QUESTIONS: StepQuestions<HP_FAST_STEPS.EXTRAS> = {
    [INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank]: {
        name: INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank,
        label: '',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'switch',
        validation: {},
        valueType: 'boolean',
        options: {
            value: '',
            label: { label: 'page.hp.extras.dhw' },
            disabled: false,
            visible: true,
        },
    },
    [INPUT_NAMES_STEP_EXTRAS.n_occupants]: {
        name: INPUT_NAMES_STEP_EXTRAS.n_occupants,
        label: 'page.hp.extras.nr_occupants',
        placeholder: 'page.hp.extras.nr_occupants.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: {},
        valueType: 'number',
    },
    [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]: {
        name: INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant,
        label: 'page.hp.extras.hot_water_per_occupant',
        placeholder: 'page.hp.extras.hot_water_per_occupant.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: {},
        valueType: 'number',
        unit: UNITS_AVAILABLE.LPD,
        tooltip: {
            label: 'page.hp.extras.hot_water_per_occupant.tooltip',
        },
    },
    [INPUT_NAMES_STEP_EXTRAS.cat_id]: {
        name: INPUT_NAMES_STEP_EXTRAS.cat_id,
        label: 'page.hp.extras.addExtra.category',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        valueType: 'number',
        options: [],
    },
    [INPUT_NAMES_STEP_EXTRAS.model_id]: {
        name: INPUT_NAMES_STEP_EXTRAS.model_id,
        label: 'page.hp.extras.addExtra.model',
        placeholder: '',
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        valueType: 'number',
        options: [],
    },
    [INPUT_NAMES_STEP_EXTRAS.quantity]: {
        name: INPUT_NAMES_STEP_EXTRAS.quantity,
        label: 'page.hp.extras.addExtra.quantity',
        placeholder: 'page.hp.extras.addExtra.quantity.placeholder',
        disabled: false,
        visible: true,
        type: 'number',
        validation: validate(['required']),
        valueType: 'number',
    },
};

export const HP_FAST_QUESTIONS: TFormQuestions = {
    [HP_FAST_STEPS.PROPERTY]: HP_FAST_STEP_PROPERTY_QUESTIONS,
    [HP_FAST_STEPS.HP_SELECTION]: HP_FAST_STEP_HP_SELECTION_QUESTIONS,
    [HP_FAST_STEPS.EXTRAS]: HP_FAST_STEP_EXTRAS_QUESTIONS,
    [HP_FAST_STEPS.BILL_OF_MATERIALS]: HP_FAST_STEP_HP_SELECTION_QUESTIONS,
    [HP_FAST_STEPS.PROPOSAL]: HP_FAST_STEP_HP_SELECTION_QUESTIONS,
} as const;

export const HP_FAST_QUESTIONS_SETTINGS: TFormQuestionsSettings = {
    [getCompanyProfileIds().FREEDOM]: {
        [USERS_TYPE_ID.MANAGER]: HP_FAST_QUESTIONS,
    },
} as const;

export const HP_FAST_KPIS_SECTIONS = {
    general: 'general',
    financial: 'financial',
    equipment_full_sol_arr: 'equipment_full_sol_arr',
    equipment_partial_sol_arr: 'equipment_partial_sol_arr',
    bom: 'bom',
} as const;

export const HP_FAST_KPIS_SUB_SECTIONS_FINANCIAL = {
    price: 'price',
} as const;

export const HP_FAST_KPIS_SUB_SECTIONS_BOM = {
    total: 'total',
    list: 'list',
    extras_arr: 'extras_arr',
} as const;

export const HP_FAST_KPIS = {
    heat_loss: 'heat_loss',
    new_running_costs: 'hp_running_costs_annual',
    running_costs_savings: 'annual_savings',
    investment: 'investment',
} as const;

export const HP_FAST_KPIS_SETTINGS = {
    [HP_FAST_KPIS_SECTIONS.general]: {
        kpis: [
            {
                name: HP_FAST_KPIS.heat_loss,
                path: HP_FAST_KPIS.heat_loss,
                label: 'page.hp.kpisDialog.generalInfo.heat_loss',
                unit: UNITS_AVAILABLE.KW,
                numberOfDecimalPlaces: 2,
                visible: true,
                showValue: (_inputs: THPFastForm) => {
                    return true;
                },
            },
        ],
        path: HP_FAST_KPIS_SECTIONS.general,
        visible: true,
    },
    [HP_FAST_KPIS_SECTIONS.financial]: {
        kpis: [
            {
                name: HP_FAST_KPIS.new_running_costs,
                path: HP_FAST_KPIS.new_running_costs,
                label: 'page.hp.kpisDialog.financial.new_running_costs',
                unit: UNITS_AVAILABLE.CURRENCY,
                numberOfDecimalPlaces: 0,
                visible: true,
                showValue: (_inputs: THPFastForm) => {
                    return true;
                },
            },
            {
                name: HP_FAST_KPIS.running_costs_savings,
                label: 'page.hp.kpisDialog.financial.running_costs_savings',
                unit: UNITS_AVAILABLE.CURRENCY,
                numberOfDecimalPlaces: 0,
                path: HP_FAST_KPIS.running_costs_savings,
                visible: true,
                showValue: (inputs: THPFastForm) => {
                    return accessObjectProp(inputs, 'property.has_heat_source');
                },
            },
            {
                name: HP_FAST_KPIS.investment,
                path: HP_FAST_KPIS_SUB_SECTIONS_FINANCIAL.price + '.' + HP_FAST_KPIS.investment,
                label: 'page.hp.kpisDialog.financial.investment',
                unit: UNITS_AVAILABLE.CURRENCY,
                numberOfDecimalPlaces: 0,
                visible: true,
                showValue: (_inputs: THPFastForm) => {
                    return true;
                },
            },
        ],
        path: HP_FAST_KPIS_SECTIONS.financial,
        visible: true,
    },
} as const;

// #endregion form constants

// #region api constants

export const HP_FAST_BASE_API_PAYLOAD = {
    is_scaling: true,
    is_fast_module: true,
    tipo_produto_id: PRODUCT_IDS.HP,
} as const;

// #endregion api constants
