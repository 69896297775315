import clone from 'fast-copy';
import _sortby from 'lodash.sortby';
import uniqby from 'lodash.uniqby';
import {
    INPUT_NAMES_STARIFFS as INPUT_NAMES,
    QUESTIONS_SUPPLY_TARIFFS_B2B,
    QUESTIONS_SUPPLY_TARIFFS_B2C,
} from 'constants/facilityPro/supplyTariffsData';
import {
    calcConvertionToUnit,
    isDefined,
    isEmptyArray,
    isFieldDefined,
    isNumberDefined,
    parseNumberWithToFixed,
    removeFieldEmptyInObj,
} from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds, hasCompanyProfileId } from 'services/user';

//#region  /** B2B **/

/* Methods Private */

/* Methods Public */
/**
 * Method to get the match between parameters and get usePower Tariff
 * @param {*} usedPowerTariffs
 * @param {*} options
 * @returns
 */
export const getUsedPowerTariff = (usedPowerTariffs, options) => {
    const { opcao_tarifario_id, nivel_tensao_id } = options;

    if (usedPowerTariffs?.length === 0 || !isFieldDefined(opcao_tarifario_id) || !isFieldDefined(opcao_tarifario_id)) {
        return `N/A`;
    }

    let usedPowerTariff = usedPowerTariffs?.find(
        (tariff) => parseInt(nivel_tensao_id) === tariff?.nivel_tensao_id && parseInt(opcao_tarifario_id) === tariff.opcao_tarifario_id
    );

    return usedPowerTariff ? parseNumberWithToFixed(usedPowerTariff?.tarifa_kw_dia, 4) : `N/A`;
};

export function getOptions(responses, options) {
    let RQST_IDX = {
        tariffOptions: 0,
        gridTariffs: 1,
        suppliers: 2,
        distributors: 3,
        hourlyOptions: 4,
        usedPowerTariffs: 5,
        gridTariffSources: 6,
        energyTariffs: 7,
    };

    const { companyProfileId } = options;

    let tariffOptions = responses?.[RQST_IDX.tariffOptions]?.data?.data ?? [];
    let gridTariffs = responses?.[RQST_IDX.gridTariffs]?.data?.data ?? [];
    let suppliers = responses?.[RQST_IDX.suppliers]?.data?.data ?? [];
    let distributors = responses?.[RQST_IDX.distributors]?.data?.data ?? [];
    let hourlyOptions = responses?.[RQST_IDX.hourlyOptions]?.data?.data ?? [];
    let usedPowerTariffs = responses?.[RQST_IDX.usedPowerTariffs]?.data?.data ?? [];
    let gridTariffSources = responses?.[RQST_IDX.gridTariffSources]?.data?.data ?? [];
    let energyTariffs = responses?.[RQST_IDX.energyTariffs]?.data?.data ?? [];

    let uniqueDistributorsIds = uniqby(tariffOptions, 'd_id');
    distributors = distributors.map((d) => ({
        ...d,
        visible:
            (
                [
                    getCompanyProfileIds().ROMANDE,
                    getCompanyProfileIds().PROFILE_BASE_SWISS,
                    getCompanyProfileIds().PROFILE_BASE_USA,
                ].includes(companyProfileId)
            ) ?
                isFieldDefined(uniqueDistributorsIds.find((el) => `${el.d_id}` === `${d.id}`))
            :   true,
    }));

    return {
        tariffOptions,
        gridTariffs,
        suppliers,
        distributors,
        hourlyOptions,
        usedPowerTariffs,
        gridTariffSources,
        energyTariffs,
    };
}

export function getSupplyTariffsDataB2B(inputs, options) {
    let defaultInputs = null;

    let { tariffOptions, isAddFacility, companyProfileId, distributors, usedPowerTariffs } = options;

    let power_fee = inputs?.power_fee ?? '';
    let tarifa_kw_dia = inputs?.tarifa_kw_dia ?? 'N/A';

    //default inputs
    if (isAddFacility) {
        //opcao_tarifario_id get default or fallback 1 element
        let tariffOptionDefault = tariffOptions?.find((el) => el.is_default) ?? tariffOptions?.[0] ?? null;
        let opcao_tarifario_id = tariffOptionDefault?.id ?? '';
        let distribuidor_id = distributors?.length === 1 ? distributors?.[0]?.id : '';

        if ([getCompanyProfileIds().EDP_PL].includes(companyProfileId)) {
            distribuidor_id = tariffOptionDefault?.d_id ?? '';
        }

        inputs = {
            ...inputs,
            supplied_by_company: '',
            fornecedor_id: '',
            distribuidor_id,
            opcao_tarifario_id,
            energy_tariffs: [],
            total_tariffs: [],
        };
    } else {
        switch (companyProfileId) {
            case getCompanyProfileIds().PPC:
            case getCompanyProfileIds().ROMANDE:
            case getCompanyProfileIds().PROFILE_BASE_USA:
            case getCompanyProfileIds().PROFILE_BASE_SWISS: {
                let hasDistributorsnByTarrifOts = isFieldDefined(
                    tariffOptions?.find((tariff) => tariff.d_id === parseFloat(inputs?.[INPUT_NAMES.DISTRIBUIDOR_ID]))
                );
                // iff the received distributor_id is not from any available distributor in the selected county, reset its value to default
                if (!hasDistributorsnByTarrifOts) {
                    let tariffDefault = tariffOptions?.find((tariff) => tariff.is_default);
                    inputs[INPUT_NAMES.DISTRIBUIDOR_ID] = `${tariffDefault?.d_id ?? ''}`;
                    inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID] = `${tariffDefault?.id ?? ''}`;
                }

                break;
            }
            case getCompanyProfileIds().EDP_PL: {
                power_fee =
                    isNumberDefined(power_fee) ? calcConvertionToUnit(parseNumberWithToFixed(power_fee, 6), 'currencykWhToMWh', 2) : '';
                break;
            }
            case getCompanyProfileIds().EDP_PT: {
                tarifa_kw_dia = getUsedPowerTariff(usedPowerTariffs, {
                    nivel_tensao_id: parseInt(inputs?.nivel_tensao_id),
                    opcao_tarifario_id: parseInt(inputs?.opcao_tarifario_id),
                });
                break;
            }
            default:
                break;
        }
    }

    // DETAILED_TARIFFS
    let detailed_tariffs = getDetailedTariffsB2B(inputs, options);

    // defaults
    defaultInputs = clone({
        [INPUT_NAMES.SUPPLIED_BY_COMPANY]: `${inputs?.supplied_by_company ?? ''}`,
        [INPUT_NAMES.FORNECEDOR_ID]: `${inputs?.fornecedor_id ?? ''}`,
        [INPUT_NAMES.DISTRIBUIDOR_ID]: `${inputs?.distribuidor_id ?? ''}`,
        [INPUT_NAMES.OPCAO_TARIFARIO_ID]: `${inputs?.opcao_tarifario_id ?? ''}`,
        [INPUT_NAMES.OPCAO_HORARIO_ID]: `${inputs?.opcao_horario_id ?? ''}`,
        [INPUT_NAMES.FONTE_TARIFA_REDES_ID]: `${inputs?.fonte_tarifa_redes_id ?? ''}`,
        [INPUT_NAMES.TARIFA_KW_DIA]: tarifa_kw_dia,
        [INPUT_NAMES.POWER_FEE]: `${power_fee ?? ''}`,
        [INPUT_NAMES.DETAILED_TARIFFS]: detailed_tariffs,
    });

    return defaultInputs;
}

export function getQuestionsSupplyTariffsDataB2B(options) {
    let questions = clone(QUESTIONS_SUPPLY_TARIFFS_B2B);

    const {
        isAddFacility,
        // userTypeID,
        companyProfileId,
    } = options;

    // SUPPLIED_BY_COMPANY
    questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].visible = [
        getCompanyProfileIds().SUNSEAP,
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().EDP_PT,
    ].includes(companyProfileId);
    questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].disabled =
        !isAddFacility &&
        [getCompanyProfileIds().SUNSEAP, getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    // FORNECEDOR_ID
    questions[INPUT_NAMES.FORNECEDOR_ID].visible = [
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().ESB,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PL,
    ].includes(companyProfileId);

    // DISTRIBUTORS_ID
    questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible = [
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PROFILE_BASE_SWISS,
        getCompanyProfileIds().PROFILE_BASE_USA,
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().EFZ,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PL,
    ].includes(companyProfileId);

    questions[INPUT_NAMES.DISTRIBUIDOR_ID].disabled = [getCompanyProfileIds().EFZ].includes(companyProfileId);

    // OPCAO_TARIFARIO_ID
    questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].visible = [
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PROFILE_BASE_SWISS,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PL,
        getCompanyProfileIds().EDP_PT,
    ].includes(companyProfileId);

    questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].disabled = [getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(
        companyProfileId
    );

    // OPCAO_HORARIO_ID
    questions[INPUT_NAMES.OPCAO_HORARIO_ID].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    // FONTE_TARIFA_REDES_ID
    questions[INPUT_NAMES.FONTE_TARIFA_REDES_ID].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    // TARIFA_KW_DIA
    questions[INPUT_NAMES.TARIFA_KW_DIA].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    // POWER_FEE
    questions[INPUT_NAMES.POWER_FEE].visible = !isAddFacility && [getCompanyProfileIds().EDP_PL].includes(companyProfileId);

    // DETAILED_TARIFFS
    let rulesHideEnergyTariff =
        !hasCompanyProfileId(companyProfileId) ||
        [
            getCompanyProfileIds().SUNSEAP,
            getCompanyProfileIds().ROMANDE,
            getCompanyProfileIds().PROFILE_BASE_SWISS,
            getCompanyProfileIds().EFZ,
            getCompanyProfileIds().PROFILE_BASE_UK,
            getCompanyProfileIds().EVOLO,
            getCompanyProfileIds().PROFILE_BASE_USA,
            getCompanyProfileIds().PROFILE_BASE_DE,
            getCompanyProfileIds().PROFILE_EDP_FR,
            getCompanyProfileIds().ESB,
            getCompanyProfileIds().NRG,
            getCompanyProfileIds().EDP_ES,
        ].includes(companyProfileId);
    questions[INPUT_NAMES.DETAILED_TARIFFS].options = {};
    questions[INPUT_NAMES.DETAILED_TARIFFS].options.hideEnergyTariff = rulesHideEnergyTariff;
    questions[INPUT_NAMES.DETAILED_TARIFFS].unit =
        [getCompanyProfileIds().ROMANDE, getCompanyProfileIds().PPC, getCompanyProfileIds().PROFILE_BASE_USA].includes(companyProfileId) ?
            'ckwh'
        :   'cmwh';

    // tooltip
    if ([getCompanyProfileIds().EDP_PL].includes(companyProfileId))
        questions[INPUT_NAMES.DETAILED_TARIFFS].tooltip = {
            grid_tariff: 'question.detailed_tariffs.tooltip.grid_tariff.comapnyID8',
        };

    return { questions, isVisible: true };
}

/**
 * getDetailedTariffs
 *
 * @param {*} inputs
 * @param {*} options
 */
export const getDetailedTariffsB2B = (inputs, options) => {
    let { energy_tariffs, total_tariffs, opcao_tarifario_id, nivel_tensao_id, distribuidor_id, potencia_contratada, opcao_horario_id } =
        inputs;

    let decimalPlaces = 4;
    let detailedTariffs = [];
    let periods = null;
    let prc_periodo = null;

    const { tariffOptions, companyProfileId, gridTariffs } = options;
    const watchDetailedTariffs = inputs?.watchDetailedTariffs ? _sortby(inputs?.watchDetailedTariffs, ['descricao']) : [];

    switch (companyProfileId) {
        case getCompanyProfileIds().SUNSEAP:
        case getCompanyProfileIds().EVOLO:
        case getCompanyProfileIds().PROFILE_BASE_UK: {
            if (!isFieldDefined(opcao_tarifario_id)) {
                return detailedTariffs;
            }

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    energy_tariffs?.length > 0 ?
                        energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id))
                    :   { tarifa: period?.default_tariff };

                //#region 2. get energy_tariff
                let energy_tariff = energyTariffData?.tarifa ?? '';
                //#endregion

                //#region 4. get total_tariff && total_tariff
                let total_tariff = '';

                if (isFieldDefined(energy_tariff)) {
                    total_tariff = parseNumberWithToFixed(energy_tariff, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff = parseNumberWithToFixed(period.default_tariff, decimalPlaces);
                }

                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(energy_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    grid_tariff: '',
                    total_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(total_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    unit: 'cmwh',
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().ROMANDE:
        case getCompanyProfileIds().PROFILE_BASE_USA:
        case getCompanyProfileIds().PROFILE_BASE_SWISS: {
            if (!isFieldDefined(opcao_tarifario_id)) {
                return detailedTariffs;
            }

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    energy_tariffs?.length > 0 ?
                        energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id))
                    :   { tarifa: period?.default_tariff };

                //#region 2. get energy_tariff
                let energy_tariff = energyTariffData?.tarifa ?? '';
                //#endregion

                //#region 4. get total_tariff
                let total_tariff = '';

                if (isFieldDefined(energy_tariff)) {
                    total_tariff = parseNumberWithToFixed(energy_tariff, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff = parseNumberWithToFixed(period.default_tariff, decimalPlaces);
                }
                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    grid_tariff: '',
                    total_tariff,
                    unit: 'ckwh',
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().PPC: {
            decimalPlaces = 3;
            if (!isFieldDefined(opcao_tarifario_id)) {
                return detailedTariffs;
            }

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    energy_tariffs?.length > 0 ?
                        energy_tariffs?.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id))
                    :   { tarifa: period?.default_tariff };

                //#region 2. get energy_tariff
                let energy_tariff = energyTariffData?.tarifa ?? '';
                //#endregion

                //#region 3. get grid_tariff
                let grid = gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));
                let grid_tariff = grid?.tarifa ?? '';
                //#endregion

                //#region 4. get total_tariff
                let totalTariffsData =
                    !isFieldDefined(energy_tariff) && total_tariffs?.length > 0 ?
                        total_tariffs?.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id))
                    :   { tarifa: null };
                let total_tariff = !isFieldDefined(energy_tariff) && totalTariffsData?.tarifa ? totalTariffsData?.tarifa : '';

                if (isFieldDefined(energy_tariff) && isFieldDefined(grid_tariff)) {
                    total_tariff =
                        parseNumberWithToFixed(energy_tariff, decimalPlaces) + parseNumberWithToFixed(grid_tariff, decimalPlaces);
                } else if (isFieldDefined(total_tariff)) {
                    energy_tariff =
                        parseNumberWithToFixed(total_tariff, decimalPlaces) - parseNumberWithToFixed(grid_tariff, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff = parseNumberWithToFixed(period.default_tariff, decimalPlaces);
                }

                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    grid_tariff,
                    total_tariff,
                    unit: 'ckwh',
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().PROFILE_BASE_IT: {
            let _gridTariffs = null;
            if (!isNumberDefined(opcao_tarifario_id) || !isNumberDefined(nivel_tensao_id) || !isNumberDefined(potencia_contratada)) {
                return detailedTariffs;
            }

            // parse Numbers
            opcao_tarifario_id = parseInt(opcao_tarifario_id);
            nivel_tensao_id = parseInt(nivel_tensao_id);
            potencia_contratada = parseInt(potencia_contratada);

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariff
            //filter gridTariffs by nivel_tensao_id
            _gridTariffs = gridTariffs?.filter((tariff) => tariff.nivel_tensao_id === nivel_tensao_id);
            //find gridTariff by range potencia_contratada
            let gridTariffIT = _gridTariffs?.find(
                (gt) =>
                    potencia_contratada >= parseFloat(gt.potencia_contratada[0].value) &&
                    potencia_contratada < parseFloat(gt.potencia_contratada[1].value)
            );
            //#endregion

            if (!isFieldDefined(gridTariffIT))
                return _sortby(
                    watchDetailedTariffs.map((el) => ({ ...el, visible: false })),
                    ['descricao']
                );

            //#region 3. build detailedTariffs
            periods?.map((period, idx) => {
                const watchEnergyTariff =
                    isNumberDefined(watchDetailedTariffs?.[idx]?.energy_tariff) ?
                        calcConvertionToUnit(watchDetailedTariffs?.[idx]?.energy_tariff, 'currencyMWhTokWh', decimalPlaces)
                    :   null;

                // 1. get energyTariffData
                let energyTariffData = energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) ?? null;

                //#region 2. get energy_tariff
                let energy_tariff = !isNumberDefined(energyTariffData?.tarifa) ? watchEnergyTariff ?? '' : energyTariffData?.tarifa;

                //#endregion

                //#region 3. get grid_tariff
                let grid_tariff = parseFloat(gridTariffIT?.tarifa);
                //#endregion

                //#region 4. get total_tariff
                let total_tariff = '';

                if (isNumberDefined(energy_tariff) && isNumberDefined(grid_tariff)) {
                    total_tariff = parseFloat(energy_tariff) + parseFloat(grid_tariff);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff = parseFloat(period.default_tariff);
                    if (isNumberDefined(grid_tariff) && total_tariff - grid_tariff > 0) {
                        energy_tariff = parseFloat(total_tariff) - parseFloat(grid_tariff);
                    } else {
                        energy_tariff = '';
                    }
                }

                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff:
                        isNumberDefined(energy_tariff) ? calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    grid_tariff: isNumberDefined(grid_tariff) ? calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    total_tariff:
                        isNumberDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    unit: 'cmwh',
                });
                //#endregion
                return period;
            });

            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().PROFILE_EDP_FR:
        case getCompanyProfileIds().PROFILE_BASE_DE: {
            if (!isNumberDefined(opcao_tarifario_id)) {
                return detailedTariffs;
            }

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    energy_tariffs?.length > 0 ?
                        energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id))
                    :   { tarifa: period?.default_tariff };

                //#region 2. get energy_tariff
                let energy_tariff = energyTariffData?.tarifa ?? '';
                //#endregion

                //#region 4. get total_tariff && total_tariff
                let total_tariff = '';

                if (isNumberDefined(energy_tariff)) {
                    total_tariff = parseNumberWithToFixed(energy_tariff, decimalPlaces);
                } else if (isNumberDefined(period.default_tariff)) {
                    total_tariff = parseNumberWithToFixed(period.default_tariff, decimalPlaces);
                }

                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(energy_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    grid_tariff: '',
                    total_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(total_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    unit: 'cmwh',
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG: {
            if (!isNumberDefined(opcao_tarifario_id) || tariffOptions?.length === 0) return detailedTariffs;

            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === parseInt(opcao_tarifario_id))?.periods ?? [];
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                //#region 1. get currentTariffDetail
                let currentTariffDetail;
                if (!isEmptyArray(total_tariffs))
                    currentTariffDetail = total_tariffs?.find((tariff) => parseInt(tariff?.tipo_periodo_id) === parseInt(period.id));
                //#endregion

                //#region 2. total_tariff
                let total_tariff = currentTariffDetail?.tarifa ?? (period.default_tariff ? period.default_tariff : '');
                //#endregion

                //#region  3. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff: '',
                    grid_tariff: '',
                    total_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(total_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    unit: 'cmwh',
                });
                //#endregion
                return period;
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().EDP_ES: {
            let periods = [];
            //#region 1. get periods
            if (isNumberDefined(opcao_tarifario_id)) {
                periods = tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? [];
            } else {
                periods = tariffOptions?.find((option) => option.is_default)?.periods ?? [];
            }
            periods = _sortby(periods, ['description']);

            // 1.1 calc % period
            if (total_tariffs?.length > 0) {
                const defaultPrcPeriodo = 1 / total_tariffs.length; // fallback -> Quando não recebemos % de periods na matriz
                periods.map((period) => {
                    let totalTariff = total_tariffs.find((item) => item.tipo_periodo_id === period.id);
                    if (totalTariff?.tarifa) {
                        period.descricao = totalTariff.descricao;
                        period.tarifa = isNumberDefined(totalTariff?.tarifa) ? totalTariff.tarifa : period.tarifa;
                        period.prc_periodo =
                            isNumberDefined(totalTariff?.prc_periodo) ? totalTariff.prc_periodo : period.prc_periodo ?? defaultPrcPeriodo;
                        period.tipo_periodo_id = totalTariff.tipo_periodo_id;
                    }
                    return period;
                });
            }
            //#endregion

            //#region 2. build detailedTariffs
            periods.map((period) => {
                //#region  3. add detailedTariffs
                // get total_tariff
                if (!isNumberDefined(period?.tarifa) && isNumberDefined(period?.default_tariff)) {
                    // fallback
                    period.tarifa = period?.default_tariff;
                    period.tipo_periodo_id = period?.id;
                    period.prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
                    period.descricao = period.description;
                } else {
                    period.tarifa = isNumberDefined(period?.tarifa) ? period?.tarifa : '';
                }
                let total_tariff = isNumberDefined(period?.tarifa) ? period?.tarifa : '';

                //#endregion
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff: '',
                    grid_tariff: '',
                    total_tariff:
                        isNumberDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    unit: 'cmwh',
                });
                //#endregion
            });
            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().EDP_IT: {
            let _gridTariffs = null;
            if (!isNumberDefined(opcao_tarifario_id) || !isNumberDefined(nivel_tensao_id) || !isNumberDefined(potencia_contratada))
                return detailedTariffs;

            nivel_tensao_id = parseInt(nivel_tensao_id);
            opcao_tarifario_id = parseInt(opcao_tarifario_id);
            potencia_contratada = parseFloat(potencia_contratada);

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === opcao_tarifario_id)?.periods ?? []
                :   { periods: [] };
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariff
            //filter gridTariffs by nivel_tensao_id
            _gridTariffs = gridTariffs?.filter((tariff) => parseInt(tariff.nivel_tensao_id) === nivel_tensao_id);
            //find gridTariff by range potencia_contratada
            let gridTariffIT = _gridTariffs?.find(
                (gt) =>
                    potencia_contratada >= parseFloat(gt.potencia_contratada[0].value) &&
                    potencia_contratada < parseFloat(gt.potencia_contratada[1].value)
            );
            //#endregion

            if (!isFieldDefined(gridTariffIT))
                return _sortby(
                    watchDetailedTariffs.map((el) => ({ ...el, visible: false })),
                    ['descricao']
                );

            //#region 3. build detailedTariffs
            periods?.map((period, idx) => {
                const watchEnergyTariff =
                    isNumberDefined(watchDetailedTariffs?.[idx]?.energy_tariff) ?
                        calcConvertionToUnit(watchDetailedTariffs?.[idx]?.energy_tariff, 'currencyMWhTokWh', decimalPlaces)
                    :   null;

                // 1. get energyTariffData
                let energyTariffData = energy_tariffs?.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) ?? null;

                //#region 2. get energy_tariff
                let energy_tariff = energyTariffData?.tarifa ? energyTariffData?.tarifa : watchEnergyTariff ?? '';
                //#endregion

                //#region 3. get grid_tariff
                let grid_tariff = gridTariffIT?.tarifa ? gridTariffIT?.tarifa : '';
                //#endregion

                //#region 4. get total_tariff
                let total_tariff = '';

                if (isNumberDefined(energy_tariff) && isNumberDefined(grid_tariff)) {
                    total_tariff = parseNumberWithToFixed(energy_tariff + grid_tariff, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff = period.default_tariff;
                    if (isNumberDefined(grid_tariff) && total_tariff - grid_tariff > 0) {
                        energy_tariff = (total_tariff - grid_tariff).toFixed(decimalPlaces);
                    } else {
                        energy_tariff = '';
                    }
                }

                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff:
                        isNumberDefined(energy_tariff) ? calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    grid_tariff: isNumberDefined(grid_tariff) ? calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    total_tariff:
                        isNumberDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    unit: 'cmwh',
                });

                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().EDP_PL: {
            let _gridTariffs = null;
            if (!isNumberDefined(opcao_tarifario_id) || !isNumberDefined(distribuidor_id)) {
                return watchDetailedTariffs?.length > 0 ? watchDetailedTariffs?.map((el) => ({ ...el, visible: false })) : detailedTariffs;
            }

            distribuidor_id = parseInt(distribuidor_id);
            opcao_tarifario_id = parseInt(opcao_tarifario_id);

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.d_id) === distribuidor_id && parseInt(item.id) === opcao_tarifario_id)
                        ?.periods ?? []
                :   { periods: [] };
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariff
            //find gridTariff by range potencia_contratada
            _gridTariffs = gridTariffs?.filter(
                (grid) => parseInt(grid.distribuidor_id) === distribuidor_id && parseInt(grid.opcao_tarifario_id) === opcao_tarifario_id
            );
            //#endregion

            console.log();

            if (!isDefined(_gridTariffs))
                return _sortby(
                    watchDetailedTariffs.map((el) => ({ ...el, visible: false })),
                    ['descricao']
                );

            //#region 3. build detailedTariffs
            periods?.map((period, idx) => {
                const watchEnergyTariff =
                    isNumberDefined(watchDetailedTariffs?.[idx]?.energy_tariff) ?
                        calcConvertionToUnit(watchDetailedTariffs?.[idx]?.energy_tariff, 'currencyMWhTokWh', decimalPlaces)
                    :   null;

                // 1. get energyTariffData
                let energyTariffData = energy_tariffs?.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) ?? null;

                // 2. get grid by tipo_periodo_id
                let grid = _gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));

                //#region 3. get energy_tariff && energy_tariff_mwh
                let energy_tariff = energyTariffData?.tarifa ?? watchEnergyTariff ?? '';
                energy_tariff = !isNumberDefined(energy_tariff) ? '' : energy_tariff;
                //#endregion

                //#region 4. get grid_tariff && grid_tariff_mwh
                let grid_tariff = grid?.tarifa ? grid?.tarifa : '';
                //#endregion

                //#region 5. get total_tariff_mwh && total_tariff
                let total_tariff =
                    isNumberDefined(energy_tariff) && isNumberDefined(grid_tariff) ?
                        parseNumberWithToFixed(energy_tariff + grid_tariff, decimalPlaces)
                    :   '';
                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff:
                        isNumberDefined(energy_tariff) ? calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    grid_tariff: isNumberDefined(grid_tariff) ? calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    total_tariff:
                        isNumberDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    unit: 'cmwh',
                });

                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().EDP_PT: {
            let _gridTariffs = null;
            let periods = [];
            opcao_horario_id = parseInt(opcao_horario_id);
            nivel_tensao_id = parseInt(nivel_tensao_id);
            potencia_contratada = parseFloat(potencia_contratada);
            //#region 1. get periods
            if (isNumberDefined(opcao_tarifario_id)) {
                periods = tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? [];
            } else {
                periods = tariffOptions?.find((option) => option.is_default)?.periods ?? [];
            }
            periods = _sortby(periods, ['id']);

            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion
            //#region 2. get gridTariff
            //filter gridTariffs by nivel_tensao_id and opcao_horario_id and potencia_contratada
            _gridTariffs = gridTariffs?.filter(
                (gt) =>
                    parseInt(gt.nivel_tensao_id) === nivel_tensao_id &&
                    parseInt(gt.opcao_horario_id) === opcao_horario_id &&
                    (gt.potencia_contratada[0]?.inclusive ?
                        potencia_contratada >= parseFloat(gt.potencia_contratada[0].value)
                    :   potencia_contratada > parseFloat(gt.potencia_contratada[0].value)) &&
                    (gt.potencia_contratada[1]?.inclusive ?
                        potencia_contratada <= parseFloat(gt.potencia_contratada[1].value)
                    :   potencia_contratada < parseFloat(gt.potencia_contratada[1].value))
            );
            //find gridTariff by range potencia_contratada

            //#endregion

            //#region 3. build detailedTariffs
            periods.map((period) => {
                const watchEnergyTariff =
                    watchDetailedTariffs?.find((el) => parseInt(el.tipo_periodo_id) === parseInt(period?.id))?.energy_tariff ?
                        calcConvertionToUnit(
                            watchDetailedTariffs?.find((el) => parseInt(el.tipo_periodo_id) === parseInt(period?.id))?.energy_tariff,
                            'currencyMWhTokWh',
                            decimalPlaces
                        )
                    :   null;

                // 1. get energyTariffData
                let energyTariffData = energy_tariffs?.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) ?? null;

                //#region 2. get energy_tariff
                let energy_tariff = !isNumberDefined(energyTariffData?.tarifa) ? watchEnergyTariff ?? '' : energyTariffData?.tarifa;

                //#endregion

                //#region 3. get grid_tariff
                let grid = _gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));

                let grid_tariff = grid?.tarifa ?? '';
                //#endregion

                //#region 4. get total_tariff
                let total_tariff = '';

                if (isNumberDefined(energy_tariff) && isNumberDefined(grid_tariff)) {
                    total_tariff = parseNumberWithToFixed(energy_tariff + grid_tariff, decimalPlaces);
                } else if (isNumberDefined(period.default_tariff)) {
                    total_tariff = period.default_tariff;
                    if (isNumberDefined(grid_tariff) && total_tariff - grid_tariff > 0) {
                        energy_tariff = (total_tariff - grid_tariff).toFixed(decimalPlaces);
                    } else {
                        energy_tariff = '';
                    }
                }

                //#endregion

                return detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo: prc_periodo,
                    energy_tariff:
                        isNumberDefined(energy_tariff) ? calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    grid_tariff: isNumberDefined(grid_tariff) ? calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    total_tariff:
                        isNumberDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '',
                    unit: 'cmwh',
                });
                //#endregion
            });
            return _sortby(detailedTariffs, ['id']);
        }
        case getCompanyProfileIds().EFZ:
        default: {
            //deauflt

            if (!isNumberDefined(opcao_tarifario_id) || tariffOptions?.length === 0) return detailedTariffs;

            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === parseInt(opcao_tarifario_id))?.periods ?? [];
            periods = _sortby(periods, ['description']);
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                //#region 1. get currentTariffDetail
                let currentTariffDetail;
                if (!isEmptyArray(total_tariffs))
                    currentTariffDetail = total_tariffs?.find((tariff) => parseInt(tariff?.tipo_periodo_id) === parseInt(period.id));
                //#endregion

                //#region 2. total_tariff
                let total_tariff = currentTariffDetail?.tarifa ?? (period.default_tariff ? period.default_tariff : '');
                //#endregion

                //#region  3. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff: '',
                    grid_tariff: '',
                    total_tariff: calcConvertionToUnit(
                        parseNumberWithToFixed(total_tariff, decimalPlaces),
                        'currencykWhToMWh',
                        decimalPlaces
                    ),
                    unit: 'cmwh',
                });
                //#endregion
                return period;
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
    }
};

export const calcRowPeriodTariffsElectricityMeanMWh = (inputName, inputValue, tariffs, companyProfileId) => {
    let isValidValue = !isNaN(inputValue);
    let decimalPlaces = 2;

    // se não for input valido, reset values
    if (!isValidValue) {
        tariffs.energy_tariff_mwh = tariffs.energy_tariff = tariffs.total_tariff_mwh = tariffs.total_tariff = '';
        return tariffs;
    }

    //#region inputName === energy_tariff_mwh
    if (inputName === 'energy_tariff_mwh') {
        //#region energy_tariff
        let energy_tariff_mwh = !isNaN(inputValue) ? parseNumberWithToFixed(inputValue, decimalPlaces) : '';
        tariffs.energy_tariff_mwh = energy_tariff_mwh;
        let energy_tariff = !isNaN(energy_tariff_mwh) ? calcConvertionToUnit(energy_tariff_mwh, 'currencyMWhTokWh') : '';
        tariffs.energy_tariff = energy_tariff;
        //#endregion

        //#region total_tariff
        let total_tariff_mwh =
            !isNaN(energy_tariff_mwh) && !isNaN(tariffs.grid_tariff_mwh) ?
                parseNumberWithToFixed(energy_tariff_mwh + tariffs.grid_tariff_mwh, decimalPlaces)
            :   '';
        tariffs.total_tariff_mwh = total_tariff_mwh;
        let total_tariff = !isNaN(energy_tariff_mwh) ? calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh') : '';
        tariffs.total_tariff = total_tariff;
        //#endregion
    }
    //#endregion

    //#region inputName === total_tariff_mwh
    if (inputName === 'total_tariff_mwh') {
        //#region total_tariff
        let total_tariff_mwh = !isNaN(inputValue) ? parseNumberWithToFixed(inputValue, decimalPlaces) : '';
        tariffs.total_tariff_mwh = total_tariff_mwh;
        let total_tariff = !isNaN(total_tariff_mwh) ? total_tariff_mwh : '';
        tariffs.total_tariff = total_tariff;
        //#endregion

        //#region energy_tariff
        //apenas para PL e IT e PT
        if ([getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            let calcEnergyTariff_mwh =
                !isNaN(total_tariff_mwh) && !isNaN(tariffs.grid_tariff_mwh) ? total_tariff_mwh - tariffs.grid_tariff_mwh : '';
            if (!isNaN(total_tariff_mwh) && calcEnergyTariff_mwh >= 0) {
                // energy_tariff_mwh
                tariffs.energy_tariff_mwh = parseNumberWithToFixed(calcEnergyTariff_mwh, decimalPlaces);
                tariffs.energy_tariff = calcConvertionToUnit(calcEnergyTariff_mwh, 'currencyMWhTokWh');
            } else {
                //se o calc for < 0, reset input energy_tariff_mwh
                tariffs.energy_tariff_mwh = tariffs.energy_tariff = '';
            }
        }
        //#endregion
    }
    //#endregion

    return tariffs;
};
//#endregion /** B2B **/

//#region /** B2C **/

/* Methods Private */

/* Methods Public */

/**
 * getDetailedTariffs
 *
 * @param {*} companyProfileId
 * @param {*} distribuidor_id
 * @param {*} opcao_tarifario_id
 * @param {*} energy_tariffs
 * @param {*} total_tariffs
 * @param {*} gridTariffs
 * @param {*} tariffOptions
 * @param {*} options
 */
export const getDetailedTariffsB2C = (inputs, options) => {
    let { energy_tariffs, total_tariffs, opcao_tarifario_id } = inputs;

    let { isAddFacility, companyProfileId, tariffOptions, gridTariffs } = options;

    let unit = 'ckwh';
    let decimalPlaces = 4;
    let detailedTariffs = [];
    let periods = null;

    const hasTotalTariffs = total_tariffs?.length > 0;

    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_SWISS:
        case getCompanyProfileIds().PROFILE_BASE_DE:
        case getCompanyProfileIds().PROFILE_EDP_FR:
        case getCompanyProfileIds().PROFILE_BASE_IT:
        case getCompanyProfileIds().EVOLO:
        case getCompanyProfileIds().PROFILE_BASE_USA:
        case getCompanyProfileIds().PROFILE_BASE_UK: {
            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === parseInt(opcao_tarifario_id))?.periods;
            if (isAddFacility && !periods?.length) {
                //default
                periods = tariffOptions?.[0]?.periods;
            }

            if (!hasTotalTariffs && periods?.length > 0) {
                const prcPeriod = parseNumberWithToFixed(1 / periods?.length, 4);

                periods.map((period) => {
                    const is_cmwh = unit === 'cmwh';
                    let total_tariff = '';
                    //#region total_tariff
                    let default_tariff = period?.default_tariff ?? '';
                    if (isFieldDefined(default_tariff)) {
                        total_tariff = parseNumberWithToFixed(period.default_tariff, decimalPlaces);
                    }

                    if (isAddFacility && !isFieldDefined(default_tariff)) {
                        total_tariff = 0.15; //default in ckwh
                    }

                    total_tariff = !isFieldDefined(total_tariff) ? '' : parseNumberWithToFixed(total_tariff, decimalPlaces);

                    //#endregion total_tariff
                    return detailedTariffs.push({
                        id: period.id,
                        visible: true,
                        tipo_periodo_id: period.id,
                        descricao: period.description,
                        prc_periodo: prcPeriod,
                        energy_tariff: '',
                        grid_tariff: '',
                        total_tariff: is_cmwh ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : total_tariff,
                        unit,
                    });
                });
            }
            //#endregion

            //#region 2. build detailedTariffs
            total_tariffs?.map((period) => {
                const is_cmwh = unit === 'cmwh';
                const defaultTariff =
                    period.tarifa ?? periods?.find((p) => parseInt(p.id) === parseInt(period.tipo_periodo_id)).default_tariff;
                //#region  3. add detailedTariffs
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff: '',
                    grid_tariff: '',
                    total_tariff: is_cmwh ? calcConvertionToUnit(defaultTariff, 'currencykWhToMWh', decimalPlaces) : defaultTariff,
                    unit: 'ckwh',
                });
                //#endregion
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().PPC: {
            let energy_tariff = '';
            let grid_tariff = '';
            let total_tariff = '';

            let VAT = 0.06; // VAT is 6% in Greece

            //#region 1. get periods
            if (isAddFacility) {
                //default
                periods = tariffOptions?.[0]?.periods;
                energy_tariff = 0.15; //kwh
                grid_tariff = gridTariffs?.[0]?.tarifa;
                total_tariff = (grid_tariff + energy_tariff) * (1 + VAT);
            } else {
                periods = tariffOptions?.find((option) => parseInt(option?.id) === opcao_tarifario_id)?.periods;
                total_tariff = total_tariffs?.[0]?.tarifa ?? periods?.[0]?.default_tariff;
                grid_tariff = gridTariffs?.[0]?.tarifa;
                energy_tariff =
                    !energy_tariffs?.length || energy_tariffs?.[0]?.tarifa === total_tariff ?
                        total_tariff / (1 + VAT) - grid_tariff
                    :   energy_tariffs?.[0]?.tarifa;
            }

            if (!hasTotalTariffs && periods?.length > 0) {
                const prcPeriod = parseNumberWithToFixed(1 / periods?.length, 4);

                periods.map((period) => {
                    return detailedTariffs.push({
                        id: period.id,
                        visible: true,
                        tipo_periodo_id: period.id,
                        descricao: period.description,
                        prc_periodo: prcPeriod,
                        energy_tariff,
                        grid_tariff,
                        total_tariff,
                        unit: 'ckwh',
                    });
                });
            }
            //#endregion

            //#region 2. build detailedTariffs
            total_tariffs.map((period) => {
                const defaultTariff =
                    period.tarifa ?? periods?.find((p) => parseInt(p.id) === parseInt(period.tipo_periodo_id)).default_tariff;
                //#region  3. add detailedTariffs
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff,
                    grid_tariff,
                    total_tariff: defaultTariff,
                    unit: 'ckwh',
                });
                //#endregion
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
        default: //default tariffsBy // New companies
            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === opcao_tarifario_id)?.periods;

            if (!hasTotalTariffs && periods?.length > 0) {
                const prcPeriod = parseNumberWithToFixed(1 / periods?.length, 4);
                periods.map((period) => {
                    return detailedTariffs.push({
                        id: period.id,
                        visible: true,
                        tipo_periodo_id: period.id,
                        descricao: period.description,
                        prc_periodo: prcPeriod,
                        energy_tariff: '',
                        energy_tariff_mwh: '',
                        grid_tariff: '',
                        grid_tariff_mwh: '',
                        total_tariff_mwh: '',
                        total_tariff: '',
                        unit: 'ckwh',
                    });
                });
            }
            //#endregion

            //#region 2. build detailedTariffs
            total_tariffs.map((period) => {
                const defaultTariff =
                    period.tarifa ?? periods?.find((p) => parseInt(p.id) === parseInt(period.tipo_periodo_id)).default_tariff;
                //#region  3. add detailedTariffs
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff: '',
                    energy_tariff_mwh: '',
                    grid_tariff: '',
                    grid_tariff_mwh: '',
                    total_tariff_mwh: calcConvertionToUnit(defaultTariff, 'currencykWhToMWh', decimalPlaces),
                    total_tariff: defaultTariff,
                    unit: 'ckwh',
                });
                //#endregion
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
    }
};

export function getSupplyTariffsDataB2C(inputs, options) {
    let defaultInputs = null;

    let { isAddFacility, tariffOptions, companyProfileId, gridTariffs } = options;

    //default inputs
    if (isAddFacility) {
        let opcao_tarifario_id = tariffOptions?.find((option) => option?.is_default)?.id ?? tariffOptions?.[0]?.id ?? ''; //ger default
        inputs = {
            ...inputs,
            [INPUT_NAMES.OPCAO_TARIFARIO_ID]: opcao_tarifario_id,
            total_tariffs: [],
        };
    }

    // DETAILED_TARIFFS
    let detailed_tariffs = getDetailedTariffsB2C(
        inputs, //inputs
        {
            //options
            isAddFacility,
            companyProfileId,
            tariffOptions,
            gridTariffs,
        }
    );

    defaultInputs = removeFieldEmptyInObj({
        facilityId: inputs?.[INPUT_NAMES.ID],
        [INPUT_NAMES.DETAILED_TARIFFS]: detailed_tariffs,
        [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs?.[INPUT_NAMES.OPCAO_TARIFARIO_ID] ?? '',
        [INPUT_NAMES.DISTRIBUIDOR_ID]: inputs?.[INPUT_NAMES.DISTRIBUIDOR_ID] ?? '',
    });

    return defaultInputs;
}

export function getQuestionsSupplyTariffsDataB2C(options) {
    let questions = clone(QUESTIONS_SUPPLY_TARIFFS_B2C);

    const { companyProfileId } = options;

    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO: {
            questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].visible = true;
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_SWISS: {
            questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].visible = true;
            questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible = true;
            break;
        }
        default:
            break;
    }

    // DETAILED_TARIFFS
    let rulesHideEnergyTariff =
        !hasCompanyProfileId(companyProfileId) ||
        [
            getCompanyProfileIds().PROFILE_BASE_SWISS,
            getCompanyProfileIds().PROFILE_BASE_UK,
            getCompanyProfileIds().EVOLO,
            getCompanyProfileIds().PROFILE_BASE_USA,
            getCompanyProfileIds().PROFILE_BASE_DE,
            getCompanyProfileIds().PROFILE_EDP_FR,
            getCompanyProfileIds().PROFILE_BASE_IT,
        ].includes(companyProfileId);
    questions[INPUT_NAMES.DETAILED_TARIFFS].options = {};
    questions[INPUT_NAMES.DETAILED_TARIFFS].options.hideEnergyTariff = rulesHideEnergyTariff;
    // questions[INPUT_NAMES.DETAILED_TARIFFS].unit =  [COMPANY_IDS.PPC].includes(userCompanyID) ? 'cmwh' : 'ckwh';

    return { questions, isVisible: true };
}

//#endregion  /** B2C **/
