import axios, { axiosOpenAreaInstance, axiosPublicInstance } from 'services/@efz/axios';
import { ResponseType } from 'axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';

import {
    getEndpoint,
    URL_PRODUCTS,
    URL_SIMULATIONS,
    URL_PV_OPTIONS,
    URL_INVERTERS,
    URL_REMUNERATIONS,
    URL_PRODUCTIONS_CONTRACT,
    URL_FACILITIES,
    URL_IMG_CONTRACT,
    URL_DS,
    URL_PRODUCTION_SOURCES,
    URL_INVERTER_COMBINATION,
    //URL_VALID_PANELS
} from 'constants/endpoints';
import { OK, RESPONSE_CANCELED_BY_USER } from 'constants/statusCodeHttp';
import { USER_COUNTRY_LatLng } from 'constants/user';
import { useFeatureFlags } from 'store/featureFlags';
import { isEnvDevFlag } from 'services/settings';

const CancelToken = axios.CancelToken;
let postBatterySimulationCancelExecutor;
let getPVOptionsCancelExecutor;
let getPVValidPanelsCancelExecutor;
let getAllInvertersCancelExecutor;
let getAllInvertersWithCostsCancelExecutor;

//Get Row Ratio
export const getPVRowRatio = async (params, abortController) => {
    return axios
        .get(`${getEndpoint(URL_DS)}/row-ratio`, {
            params,
            signal: abortController?.signal,
        })
        .then((response) => {
            return (
                !response?.status ? RESPONSE_CANCELED_BY_USER
                : response?.status !== OK ? response
                : response.data
            );
        });
};

//Post useful area
export const postSPVUsefulArea = (payload, abortController) => {
    return axios
        .request({
            method: 'POST',
            url: `${getEndpoint(URL_DS)}/useful-area`,
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Valid Panels
export const getPVValidPanels = async (payload) => {
    getPVValidPanelsCancelExecutor && getPVValidPanelsCancelExecutor();

    return axios
        .post(`${getEndpoint(URL_DS)}/valid-panels`, payload, {
            cancelToken: new CancelToken(function executor(c) {
                getPVValidPanelsCancelExecutor = c;
            }),
        })
        .then((response) => response.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post SimulationSPV
export const postBatterySimulation = (payload) => {
    postBatterySimulationCancelExecutor && postBatterySimulationCancelExecutor();

    return axios
        .post(getEndpoint(URL_SIMULATIONS), payload, {
            cancelToken: new CancelToken(function executor(c) {
                postBatterySimulationCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get PV Options
export const getPVOptions = ({ pid }, isOArea = false) => {
    if (!getPVOptionsCancelExecutor) {
        getPVOptionsCancelExecutor && getPVOptionsCancelExecutor.abort();
        getPVOptionsCancelExecutor = new AbortController();
    }
    if (isOArea) {
        return axiosOpenAreaInstance
            .request({
                url: `${getEndpoint(URL_PV_OPTIONS)}/${pid ?? ''}`,
                method: 'GET',
                signal: getPVOptionsCancelExecutor?.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request({
                url: `${getEndpoint(URL_PV_OPTIONS)}/${pid ?? ''}`,
                method: 'GET',
                signal: getPVOptionsCancelExecutor?.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getRemunerationOptions = (params: { pid: number }, isOArea = false, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    const requestCfg = {
        url: `${getEndpoint(URL_REMUNERATIONS)}`,
        method: 'GET',
        signal: abortController.signal,
        params: params,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getPVKitsB2C = (produtId, params, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS)}/${produtId}/kits`,
            method: 'GET',
            signal: abortController.signal,
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postAutoExclusions = (payload, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            method: 'POST',
            url: `${getEndpoint(URL_DS)}/exclusion-area/auto-detected`,
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get all inverters
export const getAllInverters = () => {
    getAllInvertersCancelExecutor && getAllInvertersCancelExecutor();
    return axios
        .get(getEndpoint(URL_INVERTERS), {
            cancelToken: new CancelToken(function executor(c) {
                getAllInvertersCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get all inverters (with costs)
export const getAllInvertersWithCosts = (payload) => {
    getAllInvertersWithCostsCancelExecutor && getAllInvertersWithCostsCancelExecutor();
    return axios
        .get(getEndpoint(URL_INVERTERS), {
            params: payload,
            cancelToken: new CancelToken(function executor(c) {
                getAllInvertersWithCostsCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// getCoordinatesConversionFactor
export const getCoordinatesConversionFactor = ({ lat, lng, facilityID, userLocale }, isOArea = false, abortController) => {
    const requestCfg = {
        url: `${getEndpoint(URL_DS)}/coordinates-conversion-factor`,
        method: 'POST',
        data: {
            facility_id: facilityID,
            get_geohashes: true,
            get_carto_building_gids: true,
            get_carto_aspects: true,
            save_all_carto_data: !isOArea,
            save_google_solar_data: true,
            country_alpha_2: USER_COUNTRY_LatLng?.[userLocale]?.carto,
            output: {
                conversion_factors: true,
                geohashes: false,
                building_gids: false,
                carto_aspects: false,
                all_carto_data: false,
                google_solar_data: false,
            },
            lat,
            lng,
        },
        signal: abortController.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

// getCoordinatesConversionFactor
export const postCustomizedProductionContract = (payload, abortController) => {
    abortController && abortController.abort();
    abortController = new AbortController();

    const { facilityID, body, sizingOption } = payload;

    const requestCfg = {
        url: `${getEndpoint(URL_PRODUCTIONS_CONTRACT)}/${sizingOption}`,
        params: { facility_id: facilityID },
        method: 'POST',
        data: body,
        signal: abortController.signal,
    };
    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const headCustomizedProductionContract = (payload, abortController) => {
    abortController && abortController.abort();
    abortController = new AbortController();

    const { facilityID, filename, sizingOption } = payload;

    const requestCfg = {
        url: `${getEndpoint(URL_PRODUCTIONS_CONTRACT)}/${sizingOption}`,
        params: {
            facility_id: facilityID,
            filename,
        },
        method: 'HEAD',
        signal: abortController.signal,
    };
    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getCustomizedProductionContract = (payload, abortController) => {
    abortController && abortController.abort();
    abortController = new AbortController();

    const { filename, sizingOption } = payload;

    const requestCfg = {
        url: `${getEndpoint(URL_PRODUCTIONS_CONTRACT)}/${sizingOption}`,
        params: { filename },
        method: 'GET',
        responseType: 'arraybuffer' as ResponseType,
        signal: abortController.signal,
    };
    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postSplitShape = (payload, abort) => {
    return axios
        .request({
            method: 'POST',
            url: `${getEndpoint(URL_DS)}/split-shape`,
            data: payload,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postOptimizer = (payload, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    const requestCfg = {
        url: `${getEndpoint(URL_SIMULATIONS)}/optimizer`,
        method: 'POST',
        data: payload,
        signal: abortController?.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getOptimizations = ({ facility_id, productID }, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            url: `${getEndpoint(URL_SIMULATIONS)}/optimizations`,
            method: 'GET',
            signal: abortController?.signal,
            params: { pid: productID, fid: facility_id },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getClientImage = (facilityId, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${facilityId}/${URL_IMG_CONTRACT}`,
            method: 'GET',
            responseType: 'blob',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const headClientImage = (facilityId, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${facilityId}/${URL_IMG_CONTRACT}`,
            method: 'HEAD',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getProductionSources = (abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTION_SOURCES)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postDetectBuilding = ({ facilityID, lat, lng, zoom, locale }, abortController) => {
    const requestCfg = {
        url: `${getEndpoint(URL_DS)}/detect-buildings`,
        method: 'POST',
        data: {
            facility_id: facilityID,
            country: locale,
            center: { lat, lng, zoom },
        },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postBuildingAutoHeight = ({ coords, facilityID, buildingID }, abortController) => {
    const requestCfg = {
        url: `${getEndpoint(URL_DS)}/height-from-google-solar`,
        method: 'POST',
        data: {
            area_coordinates: coords,
            facility_id: facilityID,
            building_id: buildingID,
        },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postDetectRoofType = (payload, abortController) => {
    const { facilityID, buildingID, lat, lng, zoom, coords, locale, is_manual = false } = payload;
    const flags = {
        use_carto: true,
        use_google_solar_api: true,
        use_model: true,
        geohashes: [],
        building_gids: [],
    };

    const requestCfg = {
        url: `${getEndpoint(URL_DS)}/detect-roof-type`,
        method: 'POST',
        data: {
            center: { lat, lng, zoom },
            roof: [coords],
            building_id: buildingID,
            facility_id: facilityID,
            country_alpha_2: locale,
            image_name: `autodetect-roof-${buildingID}${new Date().getTime()}.png`,
            is_manual,
            ...flags,
        },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postRoofSplit = (payload, abortController) => {
    const { facilityID, buildingID, isB2C, locale, coords, zoom, convFactors, panelDimensions, defaultRoofSlope, defaultSlope } = payload;
    const flags = {
        get_best_aspect: true,
        use_carto: true,
        use_google_solar_api: true,
        auto_detected: true,
        default_aspect: 0,
        panel_w: panelDimensions.panel_w,
        panel_l: panelDimensions.panel_l,
        row_space: 0.0,
        difPanelSlope: defaultSlope - defaultRoofSlope,
        holes_coordinates: [],
        roofSlope: defaultRoofSlope,
        setback: 0.0,
        panels_wide: 1,
        panels_up: 1,
        space_btw_sets: 1.0,
        horizontal_alignment: 'left',
        vertical_alignment: 'bottom',
        reference_point: [],
        normalize: true, // "rectangularize" computed polygons
        facility_type: isB2C ? 'b2c' : 'b2b',
        ...(isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2336']) && { is_regular_roof: !isB2C }),
    };

    const requestCfg = {
        url: `${getEndpoint(URL_DS)}/roof-split`,
        method: 'POST',
        data: {
            [`roof_${buildingID}`]: {
                roof: coords,
                zoom,
                facility_id: facilityID,
                convFactors,
                country_alpha_2: locale,
                ...flags,
            },
        },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getInvertersCombination = ({ inputs }, abortController) => {
    const requestCfg = {
        url: `${getEndpoint(URL_INVERTER_COMBINATION)}`,
        method: 'POST',
        data: { inputs },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postUploadFinModel = ({ facilityID, businessModelID, file }, abortController) => {
    const formData = new FormData();
    formData.append('fin-model', file);

    const requestCfg = {
        url: `${getEndpoint(URL_FACILITIES)}/${facilityID}/fin-models/${businessModelID}`,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        signal: abortController.signal,
    };

    return axios
        .request(requestCfg)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getStructureForRoofType = (produtId, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS)}/${produtId}/structure-for-roof-type`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getGSolarDataLayer = (payload, abortController) => {
    const params = {
        'location.latitude': payload.lat,
        'location.longitude': payload.long,
        key: process.env.REACT_APP_GOOGLE_SOLAR_API_KEY,
        radiusMeters: payload.imageRadius ?? 25,
        view: 'FULL_LAYERS',
        requiredQuality: 'LOW',
        pixelSizeMeters: 0.1,
    };

    return axiosPublicInstance
        .request({
            url: `https://solar.googleapis.com/v1/dataLayers:get`,
            params,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getGSolarTIFF = (url, abortController) => {
    const params = {
        key: process.env.REACT_APP_GOOGLE_SOLAR_API_KEY,
    };

    return axiosPublicInstance
        .request({
            url: url,
            params,
            method: 'GET',
            responseType: 'arraybuffer',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postDSTrainingData = (payload, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_DS)}/register-event`,
            data: payload,
            method: 'POST',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
