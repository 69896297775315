import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import HousingType from './HousingType';
import Address from './Address';
import RoofIdentification from './RoofIdentification';
import RoofDetails from './RoofDetails';
import Simulation from './Simulation';
import ConsumptionDetails from './ConsumptionDetails';
// Constants
import { spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
// Contexts
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
// Services / Hooks
import { getDefaultValuesB2B } from 'services/products/solarpvSimple';
import BusinessModelsContainer from './BusinessModels';
import { useSelector } from 'react-redux';
import { checkKeyDown, isDefined } from 'services/util/auxiliaryUtils';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import Proposal from './Proposal';
import { UpFrontEvents } from 'interfaces/businessModels/upfront';
import SuccessfulRequest from '../common/SuccessfulRequest';

const SolarpvSimpleFormContainer = () => {
    const bmPro = useSelector((state) => state?.bmPro);

    const {
        forwardFormId,
        locationRoof,
        spvSimpleDispatchHandler: dispatch,
        spvSimpleState: { step, isDetectRoof, inputs, isOArea, solutions, isSuccessfulRequest },
        mapState: { polygon },
        selectedCard,
    } = useContext(SPVSimpleContext);

    const { state, setBMEventHandler } = useContext(BusinessModelsProContext);

    useEffect(() => {
        if (isDefined(bmPro?.productInputs?.facility_id)) {
            dispatch(spvSimpleActions.SET_SIMULATION);
        }
    }, [bmPro?.productInputs?.facility_id]); // eslint-disable-line

    //Hooks
    const methods = useForm({
        defaultValues: {
            ...useMemo(() => getDefaultValuesB2B(inputs, isOArea), []), //eslint-disable-line
        },
        mode: 'onChange',
    });

    useEffect(() => {
        dispatch(spvSimpleActions.SET_RESET_FORM, { reset: methods.reset });
    }, [methods]); // eslint-disable-line

    const onSubmit = (formData) => {
        if (!isOArea) {
            //TODO: colocar num serviço
            switch (step) {
                case STEPS_RESERVED_AREA.HOUSING_TYPE: {
                    dispatch(spvSimpleActions.SET_HOUSING_TYPE, formData);
                    break;
                }
                case STEPS_RESERVED_AREA.ADDRESS: {
                    // TODO: falta atualizar a morada com base ao polygon desenhado
                    dispatch(spvSimpleActions.SET_ADDRESS, {
                        formData,
                        locationRoof,
                        polygon: !isDetectRoof ? polygon : null,
                    });
                    break;
                }
                case STEPS_RESERVED_AREA.ROOF_IDENTIFICATION: {
                    dispatch(spvSimpleActions.SET_ROOF_IDENTIFICATION, formData);
                    break;
                }
                case STEPS_RESERVED_AREA.ROOF_DETAILS: {
                    dispatch(spvSimpleActions.SET_ROOF_DETAILS, formData);
                    break;
                }
                case STEPS_RESERVED_AREA.SIMULATION: {
                    dispatch(spvSimpleActions.SET_REDUX_INPUTS, {
                        solution: solutions.find((el) => el.id === parseInt(selectedCard)),
                        formData,
                    });
                    break;
                }
                case STEPS_RESERVED_AREA.BUSINESS_MODELS: {
                    let payload = { bmId: state.selected.id };
                    if (isDefined(state.selected.values.offerEdition)) {
                        payload = { ...payload, offer_edition: state.selected.values.offerEdition };
                    }
                    dispatch(spvSimpleActions.SET_BUSINESS_MODELS, payload);
                    setBMEventHandler(UpFrontEvents.SET_UPFRONT_PROPOSAL, null);
                    break;
                }
                case STEPS_RESERVED_AREA.DOWNLOAD: {
                    dispatch(spvSimpleActions.SET_DOWNLOAD, formData);
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (step) {
                case STEPS_OPEN_AREA.HOUSING_TYPE: {
                    dispatch(spvSimpleActions.SET_HOUSING_TYPE, formData);
                    break;
                }
                case STEPS_OPEN_AREA.ADDRESS: {
                    dispatch(spvSimpleActions.SET_ADDRESS, {
                        formData,
                        locationRoof,
                        polygon: !isDetectRoof ? polygon : null,
                    });
                    break;
                }
                case STEPS_OPEN_AREA.ROOF_IDENTIFICATION: {
                    dispatch(spvSimpleActions.SET_ROOF_IDENTIFICATION, formData);
                    break;
                }
                case STEPS_OPEN_AREA.ROOF_DETAILS: {
                    dispatch(spvSimpleActions.SET_ROOF_DETAILS, formData);
                    break;
                }
                case STEPS_OPEN_AREA.CONSUMPTION_DETAILS: {
                    dispatch(spvSimpleActions.SET_CONSUMPTION_DETAILS, formData);
                    break;
                }
                case STEPS_OPEN_AREA.SIMULATION: {
                    dispatch(spvSimpleActions.SET_SIMULATION, formData);
                    break;
                }
                default:
                    break;
            }
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    id={forwardFormId}
                    className="solarpv-simple-form"
                    key={'formSolarpvSimple'}
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    {/* Step 1 */}
                    {step === STEPS_RESERVED_AREA.HOUSING_TYPE && <HousingType />}
                    {/* Step 2 */}
                    {step === STEPS_RESERVED_AREA.ADDRESS && <Address />}
                    {/* Step 3 */}
                    {step === STEPS_RESERVED_AREA.ROOF_IDENTIFICATION && <RoofIdentification />}
                    {/* Step 4 */}
                    {step === STEPS_RESERVED_AREA.ROOF_DETAILS && <RoofDetails />}
                    {/* Step 5 */}
                    {!isOArea && step === STEPS_RESERVED_AREA.SIMULATION && <Simulation />}
                    {isOArea && step === STEPS_OPEN_AREA.CONSUMPTION_DETAILS && <ConsumptionDetails />}
                    {/* Step 6 */}
                    {isOArea && step === STEPS_OPEN_AREA.SIMULATION && !isSuccessfulRequest && <Simulation />}
                    {isOArea && step === STEPS_OPEN_AREA.SIMULATION && isSuccessfulRequest && <SuccessfulRequest />}
                    {/* Step 7 */}
                    {!isOArea && step === STEPS_RESERVED_AREA.BUSINESS_MODELS && <BusinessModelsContainer />}
                    {/* Step 8 */}
                    {step === STEPS_RESERVED_AREA.DOWNLOAD && <Proposal />}
                </form>
            </FormProvider>
        </>
    );
};

export default SolarpvSimpleFormContainer;
